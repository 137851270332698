import betoImage from "../assets/images/OurStory/OurStoryBeto.webp";
import catyImage from "../assets/images/OurStory/OurStoryCaty.webp";
import Image from "../components/Image";
import Note from "../components/OurStory/Note";
import Story from "../components/OurStory/Story";
import StoryContainer from "../components/OurStory/StoryContainer";
import StoryImageContainer from "../components/OurStory/StoryImageContainer";
import PageContainer from "../components/PageContainer";

export default function OurStory() {
  return (
    <PageContainer page="our-story">
      <Note />
      <StoryContainer>
        <StoryImageContainer classes="top-8 xl:right-12">
          <Image
            classes="h-[44rem]"
            src={catyImage}
            alt="Beto and Cat walking away, with Cat looking back"
          />
        </StoryImageContainer>
        <Story />
        <StoryImageContainer classes="bottom-8 xl:left-12">
          <Image
            classes="h-[44rem]"
            src={betoImage}
            alt="Beto and Cat holding hands under flower arches"
          />
        </StoryImageContainer>
      </StoryContainer>
    </PageContainer>
  );
}
