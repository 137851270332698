import { FormEvent, useRef, useState } from "react";
import { useRSVPContext } from "../../context/rsvp-context";
import Button from "../Button";
import { validateEmail, validateName } from "../../util/helpers";
import { useLanguageContext } from "../../context/language-context";

interface Props {
  isLoading: boolean;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const LookupForm = ({ isLoading, onSubmit }: Props) => {
  const {
    lookupFirstName,
    lookupLastName,
    lookupEmail,
    setLookupFirstName,
    setLookupLastName,
    setLookupEmail,
  } = useRSVPContext();
  const { translate } = useLanguageContext();
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const firstName = firstNameRef.current
      ? firstNameRef.current.value.trim()
      : "";
    const lastName = lastNameRef.current
      ? lastNameRef.current.value.trim()
      : "";
    const email = emailRef.current ? emailRef.current.value.trim() : "";

    let firstNameValid = true;
    let lastNameValid = true;
    let emailValid = true;

    if (firstName && lastName) {
      firstNameValid = validateName(firstName);
      lastNameValid = validateName(lastName);
    }

    if (email) {
      emailValid = validateEmail(email);
    }

    if (
      !emailValid ||
      (firstName && !firstNameValid) ||
      (lastName && !lastNameValid)
    ) {
      setErrors({
        firstName: firstNameValid ? "" : "Invalid first name",
        lastName: lastNameValid ? "" : "Invalid last name",
        email: emailValid ? "" : "Invalid email",
      });

      return;
    }

    if (firstNameRef.current) firstNameRef.current.blur();
    if (lastNameRef.current) lastNameRef.current.blur();
    if (emailRef.current) emailRef.current.blur();

    onSubmit(event);
  };

  return (
    <>
      <h3 className="mx-auto text-center xl:w-1/3">{translate("rsvp-note")}</h3>
      <form
        onSubmit={handleSubmit}
        className="mx-auto mt-8 grid w-full max-w-lg grid-cols-1 gap-4"
      >
        <div className="flex flex-col">
          <div className="flex flex-col space-y-6 xl:flex-row xl:space-x-8 xl:space-y-0">
            <label htmlFor="firstName" className="block w-full">
              <span className="font-acherone">
                {translate("rsvp-search-first")}:
              </span>
              <input
                ref={firstNameRef}
                type="text"
                id="firstName"
                name="firstName"
                minLength={2}
                pattern="[A-Za-z\s]+"
                value={lookupFirstName}
                onChange={(e) => setLookupFirstName(e.target.value)}
                className="form-input block w-full border-2 border-primary p-4 text-xl/none focus-within:outline-none focus-within:ring focus-within:ring-inset focus-within:ring-tertiary"
                required={!lookupEmail.trim().length}
              />
              {errors.firstName && (
                <span className="font-bold text-secondary">
                  {errors.firstName}
                </span>
              )}
            </label>
            <label htmlFor="lastName" className="block w-full">
              <span className="font-acherone subpixel-antialiased">
                {translate("rsvp-search-last")}:
              </span>
              <input
                ref={lastNameRef}
                type="text"
                id="lastName"
                name="lastName"
                minLength={2}
                pattern="[A-Za-z\s]+"
                value={lookupLastName}
                onChange={(e) => setLookupLastName(e.target.value)}
                className="form-input block w-full border-2 border-primary p-4 text-xl/none focus-within:outline-none focus-within:ring focus-within:ring-inset focus-within:ring-tertiary"
                required={!lookupEmail.trim().length}
              />
              {errors.lastName && (
                <span className="font-bold text-secondary">
                  {errors.lastName}
                </span>
              )}
            </label>
          </div>
          <div className="mx-auto mb-2 mt-8 flex text-center">
            <h3 className="text-[22px]">
              {translate("rsvp-search-email-label")}
            </h3>
          </div>
          <div>
            <label htmlFor="firstName" className="block">
              <span className="text-center font-acherone">
                {translate("rsvp-search-email")}:
              </span>
              <input
                ref={emailRef}
                type="email"
                id="email"
                name="email"
                value={lookupEmail}
                onChange={(e) => setLookupEmail(e.target.value)}
                className="form-input block w-full border-2 border-primary p-4 text-xl/none focus-within:outline-none focus-within:ring focus-within:ring-inset focus-within:ring-tertiary"
                required={
                  !(
                    lookupFirstName.trim().length &&
                    lookupLastName.trim().length
                  )
                }
              />
              {errors.email && (
                <span className="font-bold text-secondary">{errors.email}</span>
              )}
            </label>
          </div>
        </div>
        <div className="flex items-center justify-evenly">
          <Button
            classes="text-xl disabled:cursor-not-allowed disabled:hover:bg-primary"
            type="submit"
            disabled={isLoading}
          >
            {translate("search")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default LookupForm;
