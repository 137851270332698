import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const Question = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <div className="border-2 border-primary bg-white p-4">
      <article className="flex w-full items-center justify-between">
        <p
          className="text-xl font-bold"
          dangerouslySetInnerHTML={{ __html: question }}
        />
        <button onClick={() => setShowAnswer(!showAnswer)} className="text-2xl">
          <IoIosArrowDown
            className={`text-black transition-transform duration-200 ease-in-out ${
              showAnswer ? "rotate-180" : ""
            }`}
          />
        </button>
      </article>
      <article
        className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
          showAnswer
            ? "grid-rows-[1fr] pt-4 opacity-100"
            : "grid-rows-[0fr] pt-0 opacity-0"
        }`}
      >
        <p
          className="overflow-hidden whitespace-pre-wrap px-12"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </article>
    </div>
  );
};

export default Question;
