import { useState } from "react";
import Navigation from "../Navigation/Navigation";
import Flyout from "../Navigation/Flyout";
import Navbar from "../Navigation/Navbar";

// TODO: Check footer styling and auto placement
export default function Body() {
  const [flyoutVisible, setFlyoutVisible] = useState(false);

  return (
    <>
      <Navbar
        flyoutVisible={flyoutVisible}
        setFlyoutVisible={setFlyoutVisible}
      />
      <Flyout
        flyoutVisible={flyoutVisible}
        setFlyoutVisible={setFlyoutVisible}
      />
      <Navigation flyoutVisible={flyoutVisible} />
      <Footer>
        <p> developed by MPJ Labs </p>
      </Footer>
    </>
  );
}

const Footer = () => {
  return (
    <div className="relative bottom-0 left-0 flex w-full items-center justify-center bg-primary py-8 text-white">
      <p> developed by MPJ Labs.</p>
    </div>
  );
};
