import travelImage from "../assets/images/Travel/TravelMain.webp";
import Image from "../components/Image";
import PageContainer from "../components/PageContainer";
import AirportInfo from "../components/Travel/AirportInfo";
import PassportInfo from "../components/Travel/PassportInfo";
import TransportationInfo from "../components/Travel/TransportationInfo";

const Travel = () => {
  return (
    <PageContainer page="travel">
      <div className="mb-4 flex flex-col xl:flex-row xl:space-x-8">
        <div className="my-8 flex flex-col xl:w-3/5">
          <PassportInfo />
          <AirportInfo />
          <TransportationInfo />
        </div>
        <div className="flex xl:w-2/5">
          <Image
            src={travelImage}
            alt="Beto holding Cat in front of a shop window"
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default Travel;
