import { useState } from "react";

interface Props {
  src: string;
  alt: string;
  classes?: string;
  url?: string;
}

const Image = ({ src, alt, classes = "", url }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const imageElement = (
    <img
      src={src}
      alt={alt}
      loading="lazy"
      className={`h-full w-full object-cover transition-opacity duration-300 ${isLoaded ? "opacity-100" : "opacity-0"}`}
      onLoad={() => setIsLoaded(true)}
    />
  );

  return (
    <div className={`relative overflow-hidden ${classes}`}>
      {!isLoaded && (
        <div className="absolute inset-0 flex h-full w-full animate-pulse-fast items-center justify-center rounded-lg bg-gradient-to-tr from-gray via-white to-gray p-2 opacity-25"></div>
      )}
      {url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {imageElement}
        </a>
      ) : (
        imageElement
      )}
    </div>
  );
};

export default Image;
