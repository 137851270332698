import { createContext, useContext, useState } from "react";
import { translations } from "../data/translations";

type Language = "en" | "es";
interface Props {
  language: Language;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
  translate: (key: string) => string;
}
const LanguageContext = createContext<Props | undefined>(undefined);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState<Language>("en");

  const translate = (key) => translations[language][key];

  const value = {
    language,
    setLanguage,
    translate,
  };
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error(
      "useLanguageContext must be used within a LanguageProvider",
    );
  }

  return context;
};
