export const translations = {
  en: {
    "recommendations": "Recommendations",
    "recommendations-text": "Mexico City is a foodie's paradise, with a vast and diverse culinary scene that offers something for everyone. From Michelin-recognized restaurants to street stalls, there's no shortage of great food to be found in this vibrant city. We have spent most of our time in this city enjoying the delicious food! Here are some of our favorite eats and fun things to do, plus a few top tips from people who know their stuff!!",
    "recommendations-map": "Recommendations Map!",
    "recommendations-map-desc": "Great travel tool!",
    "recommendations-map-desc-two": "This is a system that helps us when traveling that allows us to save places in advance that we want to check out (or already love) and then access them on the go.",
    "recommendations-open-map": "Open Map!",
    "recommendations-map-list-desc": "This list can be saved directly to your Google Maps to display dozens of locations that we have “saved” as recommended restaurants, coffee shops, bars, etc. Open and save the list on your phone or computer for future access. Use it to direct your movement around the city OR, as we use it, wander around the city to see what you discover and pull it up to see what is nearby when you need it or are ready for your next meal.",
    "our-story": "Our Story",
    schedule: "Schedule",
    registry: "Registry",
    travel: "Travel",
    hotel: "Hotel",
    "things-to-do": "Things To Do",
    faq: "FAQ",
    rsvp: "rsvp",
    "scroll-to-buttons": "scroll to buttons",
    "home-married": "We're getting married!",
    "home-details": "The details",
    "home-days": "days till the wedding",
    "home-date": "Friday November 8th 2024",
    "home-celebrate": "We can't wait to celebrate with you in Mexico City!",
    "story-note-title": "A note from us",
    "story-note":
      "Hi!!! We are so excited to invite you to our wedding in the beautiful destination of Mexico City, a vibrant city full of incredible history and culture! \n\nThis website has a lot of information on our events, the city itself, where to stay, and general info to make this a great trip. We will be updating the site with more information and recommendations as we get closer to the event. If you need anything else or have any questions in the meantime, please do not hesitate to send either of us a text or send us an email at <b>joika.casa@gmail.com</b>.\n\nIf we have not already collected your email address, please include it in RSVP so that we can stay in touch and keep you updated along the way!!\n\nWe would be remiss to not acknowledge that we have chosen to get married far from home! We know this is a big ask, both in time and financial commitment, from our guests.\n\nIf you do choose to come, we super appreciate it - it's going to be an awesome weekend, in an incredible place, one of our favorite places in the world, and we cannot wait to see you there!!!\n\nWe love you and we are so excited to celebrate with you!\n<p class='font-chicle note-end'>Caty &amp; Beto ❤</p>",
    "our-story-title": "our story",
    "our-story-paragraphOne":
      "Our adventure began in 2015 at a college event called Minerpalooza. The night was winding down, and as we headed to a friend's house, we discovered Beto's car had been broken into. Who knew a busted window could lead to true love? Ever since that fateful night, we've been stuck together like glue—no broken glass required!",
    "our-story-paragraphTwo":
      "Since then, our lives have been one big, fun-filled ride. We moved to Dallas, where Cat became an Occupational Therapist, while Beto has been thriving as a software engineer.  Our two crazy dogs (Mika & Joi) have been with us every step of the way, thinking they're the stars of the show (and they kind of are). They make plenty of appearances on our website, so keep an eye out for their photobombs!",
    "our-story-paragraphThree":
      "Although our girls won't be able to attend our wedding in Mexico City this November (trust us, we tried), they'll be celebrating with us in spirit—probably from the comfort of our couch, plotting their next adventure!",
    "schedule-drinks-title": "Drinks at <a href='http://www.wallacebar.mx/'> Wallace Whiskey Bar</a>",
    "schedule-drinks-date": "Thursday, November 7th, 2024",
    "schedule-drinks-dress": "Dress Code: Casual",
    "schedule-drinks-details": "Join us for an evening of light bites and drinks as we kick off the celebration! We can’t wait to toast with you at Wallace Whiskey Bar! It’s the perfect way to start the festivities before the big day!",
    "wedding-ceremony-title": "Wedding Ceremony",
    "wedding-ceremony-location": "Parroquia de la Sagrada Familia",
    "wedding-ceremony-date": "Friday, November 8th, 2024",
    "wedding-ceremony-time": "5:00-6:00 p.m.",
    "wedding-ceremony-details": "Dress Code: Formal/Black Tie optional",
    "wedding-ceremony-description":
      "The initial part of our celebration will take place at the Parroquia de la Sagrada Familia in Roma Norte, where we will exchange our vows in a Catholic wedding ceremony. We would like to invite you to attend this ceremony and be part of our traditions and cultural background. All are welcome regardless of their religious beliefs or preferences. Please join us for this special and important occasion in our lives, we would feel honored and happy to see you there!",
    "wedding-reception-title": "Wedding Reception",
    "wedding-reception-location": "Museo Casa de la Bola",
    "wedding-reception-date": "Friday, November 8th, 2024",
    "wedding-reception-time": "6:30 p.m. - 2:30 a.m.",
    "wedding-reception-details": "Dress Code: Formal/Black Tie optional",
    "wedding-reception-description":
      "Immediately after the wedding ceremony, join us as we transition to the reception venue! The reception will kick off with a cocktail hour and light bites, followed by a seated dinner. Then, get ready for drinks, dancing, and more drinks! We can't wait to celebrate with you!",
    "registry-title": "No Gifts",
    "registry-pointOne":
      "We so hope that you can make it to our celebration in Mexico City!",
    "registry-pointTwo":
      "It means the world to us to have you be a part of this special moment in our lives. And your presence is the greatest gift of all!",
    "registry-pointThree":
      "We understand the commitment of time and resources that comes with attending a destination wedding. In lieu of gifts, we kindly ask that you simply share in the joy of our celebration and this amazing city alongside us. Truly - enjoying this experience with us is the best gift you can give us.",
    "registry-pointFour":
      "For those who insist, we have curated a small registry with a few items linked below.",
    "registry-pointFive":
      "Thanks for being part of our story and for making this celebration what it is.",
    "travel-passport-title": "Passport",
    "travel-passport-pointOne":
      "You will need a passport if you are flying from the U.S. to Mexico City",
    "travel-passport-pointTwo":
      "Please make sure your passport is up-to-date and not expiring within 6 months of traveling to Mexico City.",
    "travel-passport-pointThree":
      "If your passport expires <b>before May 8th 2025</b> you will need to renew it",
    "travel-airport-title": "Airports",
    "travel-airport-pointOne":
      "Mexico City has two international airports: <b>Benito Juárez International Airport</b> and <b>Mexico City Felipe Ángeles International Airport</b>.",
    "travel-airport-pointTwo":
      "We recommend flying into <b>Benito Juárez</b>, also known as <b>Mexico City Airport (MEX / IATA)</b>, which is only a 20-30 minute taxi ride from the center of the city. There are both direct and connecting flights from most major US cities. However, you may find that a connecting flight gives you more flexibility on timing and price.",
    "travel-airport-pointThree":
      "Once you arrive at the MEX Airport, you will need to take a car to your hotel/AirBnB.",
    "travel-airport-pointFour":
      "You can exchange money at the airport or you can withdraw money from an ATM to get a few pesos for tipping and cash markets etc; there are many exchange stands there so just go to one with a good rate.",
    "travel-tourist-title": "Getting Around",
    "travel-tourist-pointOne":
      "We highly recommend using <b>Uber</b> to move around the city. It is very safe, convenient, and affordable.",
    "travel-tourist-pointTwo":
      "We do not recommend renting a car to drive around, as it is a city with alot of people and traffic. CDMX is the largest city in North America and sixth largest metropolitan area in the world. Due to this, traffic can be insane (think New York or Paris driver energy). Be sure to download the Uber app before your flight takes off from the states.",
    "rsvp-note":
      "If you're responding for you and a guest (or your family), you'll be able to RSVP for your entire group",
    "rsvp-search-first": "First Name",
    "rsvp-search-last": "Last Name",
    "rsvp-search-email-label":
      "If you already RSVP'd, enter email to modify it",
    "rsvp-search-email": "Email",
    "rsvp-found":
      "RSVP information has been found.<br />You can edit it if you like",
    "rsvp-edit": "Edit RSVP",
    attending: "Will be attending?",
    yes: "Yes",
    no: "No",
    email: "Email",
    children: "How many children?",
    notes: "Additional notes, dietary restrictions, concerns?",
    confirm: "Confirm",
    search: "Search",
    "new-search": "New Search",
    "guest-not-found": "Guest not found",
    "guest-found": "Guest data loaded",
    "data-fail": "Failed to load data",
    "rsvp-saved": "RSVP confirmed successfully",
    "rsvp-error": "Failed to confirm RSVP",
  },
  es: {
    "recommendations": "Recomendaciones",
    "recommendations-text": "Ciudad de México es un paraíso para los amantes de la comida, con una escena culinaria vasta y diversa que ofrece algo para todos. Desde restaurantes reconocidos por Michelin hasta puestos callejeros, no falta comida deliciosa en esta vibrante ciudad. Hemos pasado la mayor parte de nuestro tiempo aquí disfrutando de la deliciosa comida. ¡Aquí están algunos de nuestros lugares favoritos para comer y cosas divertidas para hacer, además de unos cuantos consejos de personas que saben de lo que hablan!",
    "recommendations-map": "Mapa de Recomendaciones",
    "recommendations-map-desc": "¡Una herramienta para viajar!",
    "recommendations-map-desc-two": "Este es un sistema que nos ayuda cuando viajamos y nos permite guardar con anticipación los lugares que queremos visitar (o que ya nos encantan) y luego acceder a ellos mientras estamos en movimiento.",
    "recommendations-open-map": "Abrir Mapa",
    "recommendations-map-list-desc": "Esta lista se puede guardar directamente en tu Google Maps para mostrar docenas de ubicaciones que hemos “guardado” como restaurantes recomendados, cafeterías, bares, etc. Abre y guarda la lista en tu teléfono o computadora para acceder a ella en el futuro. Úsala para guiar tus movimientos por la ciudad O, como lo hacemos nosotros, deambula por la ciudad para ver lo que descubres y ábrela para ver qué hay cerca cuando lo necesites o cuando estés listo para tu próxima comida.",
    "our-story": "Nuestra Historia",
    schedule: "Horario",
    registry: "Regalo",
    travel: "Viaje",
    "things-to-do": "Actividades",
    hotel: "Hotel",
    faq: "Preguntas",
    rsvp: "rsvp",
    "scroll-to-buttons": "desplazarse a los botones",
    "home-married": "¡Nos vamos a casar!",
    "home-details": "Los detalles",
    "home-days": "días hasta la boda",
    "home-date": "Viernes 8 de Noviembre de 2024",
    "home-celebrate":
      "Estamos emocionados de celebrar contigo en la Ciudad de México!",
    "story-note-title": "Una nota de nosotros",
    "story-note":
      "Hola!!! ¡Estamos súper emocionados de invitarte a nuestra boda en el hermoso destino de la Ciudad de México, una ciudad vibrante llena de increíble historia y cultura!\n\n En esta página web encontrarás mucha información sobre nuestros eventos, la ciudad, dónde hospedarte y más detalles para que tu viaje sea increíble. Actualizaremos el sitio con más información y recomendaciones a medida que se acerque el evento. Si necesitas algo más o tienes alguna pregunta mientras tanto, no dudes en enviarnos un mensaje de texto o escribirnos a <b>joika.casa@gmail.com</b>.\n\n Si aún no hemos recolectado tu dirección de correo electrónico, por favor inclúyela en el RSVP para que podamos mantenernos en contacto y actualizarte con las novedades.\n\n Sabemos que hemos elegido casarnos lejos de casa y reconocemos que es un gran pedido, tanto en tiempo como en compromiso financiero, para nuestros invitados.\n\n Si decides venir, ¡lo apreciamos muchísimo! Va a ser un fin de semana increíble en un lugar asombroso, uno de nuestros lugares favoritos en el mundo, ¡y no nos podemos esperar para verte allí!\n\n ¡Te queremos y estamos listos para celebrar contigo!\n\n <p class='font-chicle note-end'>Caty & Beto ❤</p>",
    "our-story-title": "nuestra historia",
    "our-story-paragraphOne":
      "Nuestra aventura comenzó en 2015 en un evento universitario llamado Minerpalooza. La noche estaba llegando a su fin, y mientras nos dirigíamos a la casa de un amigo, descubrimos que habían destrozado el carro de Beto. ¿Quién hubiera pensado que una ventana quebrada podría llevar al amor verdadero? Desde esa noche fatídica, hemos estado pegados como chicle, ¡ahora ya, sin necesidad de vidrio quebrado!",
    "our-story-paragraphTwo":
      "Desde entonces, nuestras vidas han sido un viaje lleno de diversión. Nos mudamos a Dallas, dónde Cat se tituló como Terapeuta Ocupacional, mientras que Beto ha estado trabjando como ingeniero de software. Nuestras dos perras locas (Mika y Joi) han estado con nosotros en cada paso del camino, creyendo que son las estrellas del espectáculo (y la verdad es que sí lo son). Aparecen mucho en nuestra página web, así que estén atentos a sus fotobombas.",
    "our-story-paragraphThree":
      "Aunque nuestras niñas no podrán asistir a nuestra boda en la Ciudad de México este Noviembre (créannos, lo intentamos), estarán celebrando con nosotros en espíritu, probablemente desde la comodidad de nuestro sillón, planeando su próxima aventura.",
    "schedule-drinks-title": "Bebidas en <a href='http://www.wallacebar.mx/'> Wallace Whiskey Bar</a>",
    "schedule-drinks-date": "Jueves 7 de Noviembre de 2024",
    "schedule-drinks-dress": "Código de Vestimenta: Casual",
    "schedule-drinks-details": "¡Únete a nosotros para una noche de botanas y bebidas mientras comenzamos la celebración! ¡Estamos ansiosos por brindar contigo en Wallace Whiskey Bar! ¡Es la manera perfecta de arrancar la fiesta antes del gran día!",
    "wedding-ceremony-title": "Ceremonia de la Boda",
    "wedding-ceremony-location": "Parroquia de la Sagrada Familia",
    "wedding-ceremony-date": "Viernes 8 de Noviembre de 2024",
    "wedding-ceremony-time": "5:00-6:00 p.m.",
    "wedding-ceremony-details": "Código de Vestimenta: Formal",
    "wedding-ceremony-description":
      "La parte inicial de nuestra celebración sera en la Parroquia de la Sagrada Familia en Roma Norte, donde intercambiaremos nuestros votos en una ceremonia de boda católica. Nos gustaría invitarte a asistir a esta ceremonia y ser parte de nuestras tradiciones y antecedentes culturales. Todos son bienvenidos, independientemente de sus creencias religiosas o preferencias. Por favor, acompáñanos en esta ocasión tan especial e importante en nuestras vidas, ¡Nos sentiríamos honrados y felices de verte allí!",
    "wedding-reception-title": "Wedding Reception",
    "wedding-reception-location": "Museo Casa de la Bola",
    "wedding-reception-date": "Viernes 8 de Noviembre de 2024",
    "wedding-reception-time": "6:30 p.m. - 2:30 a.m.",
    "wedding-reception-details": "Código de Vestimenta: Formal",
    "wedding-reception-description":
      "Inmediatamente después de la ceremonia religiosa, acompáñanos al lugar de la recepción! La recepción iniciará con una hora de cóctel y bocadillos, seguida de una cena formal. Luego, ¡prepárate para celebrar, bailar, y shots! ¡Estamos ansiosos por celebrar con ustedes!",
    "registry-title": "¡Sin regalos se arma!",
    "registry-pointOne":
      "¡Esperamos con muchas ganas que puedan acompañarnos en nuestra celebración en la Ciudad de México!",
    "registry-pointTwo":
      "Significa mucho para nosotros tenerte como parte de este momento especial en nuestras vidas. ¡Y tu presencia es el mayor regalo de todos!",
    "registry-pointThree":
      "Entendemos el compromiso de tiempo y recursos que implica ir a una boda de destino. En lugar de regalos, te pedimos amablemente que simplemente compartas la alegría de nuestra celebración y de esta increíble ciudad junto a nosotros. Realmente, disfrutando esta experiencia con nosotros es el mejor regalo que nos puedes dar.",
    "registry-pointFour":
      "Para aquellos que insisten, hemos curado una pequeña lista de regalos con algunos artículos que encontrarás a abajo.",
    "registry-pointFive":
      "Gracias por ser parte de nuestra historia y por hacer esta celebración lo que es.",
    "travel-passport-title": "Pasaporte",
    "travel-passport-pointOne":
      "Necesitarás un pasaporte si vuelas desde los EE.UU. (o de otro país, claro) a la Ciudad de México.",
    "travel-passport-pointTwo":
      "Por favor, asegúrate de que tu pasaporte esté actualizado y no expire dentro de los 6 meses posteriores a tu viaje a la Ciudad de México.",
    "travel-passport-pointThree": "Si tu pasaporte expira antes del 8 de mayo de 2025, necesitarás renovarlo.",
    "travel-airport-title": "Aeropuerto",
    "travel-airport-pointOne":
      "Ciudad de México tiene dos aeropuertos internacionales: el <b>Aeropuerto Internacional Benito Juárez</b> y el <b>Aeropuerto Internacional Felipe Ángeles</b>.",
    "travel-airport-pointTwo":
      "Recomendamos volar al <b>Benito Juárez</b>, también conocido como <b>Aeropuerto de la Ciudad de México (MEX / IATA)</b>, que está a solo 20-30 minutos en taxi del centro de la ciudad. Hay vuelos directos y con escalas desde la mayoría de las principales ciudades de EE.UU. Sin embargo, puede que encuentres que un vuelo con escala te da más flexibilidad en cuanto a horarios y precios.",
    "travel-airport-pointThree":
      "Una vez que llegues al Aeropuerto MEX, necesitarás tomar un coche hasta tu hotel/AirBnB.",
    "travel-airport-pointFour":
      "Puedes cambiar dinero en el aeropuerto o retirar dinero de un cajero automático para obtener algunos pesos para propinas y mercados de efectivo, etc.; hay muchos puestos de cambio allí.",
    "travel-tourist-title": "Turisteando",
    "travel-tourist-pointOne":
      "Recomendamos usar <b>Uber</b> para moverse por la ciudad. Es muy seguro, conveniente y barato.",
    "travel-tourist-pointTwo":
      "No recomendamos alquilar un coche para conducir, ya que es una ciudad con mucha gente y tráfico. CDMX es la ciudad más grande de Norteamérica y la sexta área metropolitana más grande del mundo. Debido a esto, el tráfico puede ser una locura (piensa en la energía de los conductores de Nueva York o París). Asegúrate de descargar la aplicación de Uber antes de que tu vuelo despegue desde los Estados Unidos.",
    "rsvp-note":
      "Si estás respondiendo por ti y un invitado (o tu familia), podrás confirmar la asistencia para todo tu grupo.",
    "rsvp-search-first": "Nombre",
    "rsvp-search-last": "Apellido",
    "rsvp-search-email-label":
      "Si ya has confirmado tu asistencia, ingresa tu correo electrónico para hacer cambios.",
    "rsvp-search-email": "Correo electronico",
    "rsvp-found":
      "Se ha encontrado información de confirmación de asistencia.<br/>Puedes editarlo si quieres.",
    "rsvp-edit": "Editar confirmación de asistencia",
    attending: "¿Asistirá?",
    yes: "Sí",
    no: "No",
    email: "Correo electrónico",
    children: "¿Cuantos niños?",
    notes: "¿Notas adicionales, restricciones dietéticas, inquietudes?",
    confirm: "Confirmar",
    search: "Buscar",
    "new-search": "Nueva Búsqueda",
    "guest-not-found": "Invitado no encontrado",
    "guest-found": "Datos del huésped cargados",
    "data-fail": "No se pudieron cargar los datos",
    "rsvp-saved": "RSVP confirmado con éxito",
    "rsvp-error": "No se pudo confirmar la confirmación de asistencia",
  },
};
