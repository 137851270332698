import noteImage from "../../assets/images/OurStory/OurStoryNote.webp";
import { useLanguageContext } from "../../context/language-context";
import Image from "../Image";

// TODO: If Note data becomes dynamic, we need to protect against XSS
const Note = () => {
  const { translate } = useLanguageContext();

  return (
    <div className="flex w-full flex-col items-center justify-center xl:flex-row xl:space-x-2">
      <div className="basis-5/12">
        <Image
          src={noteImage}
          alt="Beto and Cat with the dogs around a fire pit"
          classes="xl:h-[44rem] 2xl:h-[40rem]"
        />
      </div>
      <div className="mx-2 my-8 flex basis-7/12 flex-col border bg-linen bg-gradient-to-r from-linen via-white to-linen p-6 shadow-lg xl:mx-auto xl:h-[44rem] 2xl:h-[40rem]">
        <div className="mb-2 flex items-center justify-between">
          <h2 className="font-neville">{translate("story-note-title")}</h2>
        </div>
        <p
          className="whitespace-pre-wrap"
          dangerouslySetInnerHTML={{
            __html: translate("story-note"),
          }}
        />
      </div>
    </div>
  );
};

export default Note;
