import { ForwardedRef, forwardRef } from "react";

import { useLanguageContext } from "../../context/language-context";
import { QuestionsEN, QuestionsES } from "../../data/questions";
import ScrollToButton from "./ScrollToButton";

interface Props {
  onClick: (key: string) => void;
}

const SectionButtons = forwardRef<HTMLDivElement, Props>(
  ({ onClick }, ref: ForwardedRef<HTMLDivElement>) => {
    const { language } = useLanguageContext();
    const questionsToMap = language === "en" ? QuestionsEN : QuestionsES;

    return (
      <div ref={ref} className="mx-auto flex max-w-lg scroll-mt-64">
        {Object.keys(questionsToMap).map((key) => {
          let buttonText = key;
          if (key === "Confirmacion de asistencia (RSVP)") buttonText = "rsvp";
          if (key === "Preguntas generales") buttonText = "general";

          return (
            <ScrollToButton onClick={() => onClick(key)} key={key}>
              {buttonText}
            </ScrollToButton>
          );
        })}
      </div>
    );
  },
);

export default SectionButtons;
