import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import discoGif from "../assets/images/DiscoBall.gif";
import Button from "../components/Button";
import Image from "../components/Image";
import Loading from "../components/Loading";
import PageContainer from "../components/PageContainer";
import LookupForm from "../components/RSVP/LookupForm";
import RSVPForm from "../components/RSVP/RSVPForm";
import { EMPTY_GUESTS } from "../util/constants";
import { useGuestLookup, useRSVP } from "../util/hooks";
import { useLanguageContext } from "../context/language-context";

const RSVP = () => {
  const { translate } = useLanguageContext();
  const resetGuests = () => setGuests(EMPTY_GUESTS);

  const { guests, isLoadingLookup, setGuests, handleLookupSubmit } =
    useGuestLookup(resetGuests);
  const { isLoadingRSVP, handleRSVPSubmit } = useRSVP(guests, resetGuests);

  return (
    <PageContainer page="rsvp">
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <div className="mx-auto">
        <Image
          src={discoGif}
          alt="Disco ball rotating"
          classes="h-36 w-32 xl:w-full xl:h-56"
        />
      </div>
      <div className="m-4 grid">
        {guests.rsvps.length === 0 ? (
          <LookupForm
            isLoading={isLoadingLookup}
            onSubmit={handleLookupSubmit}
          />
        ) : (
          <Button
            classes="bg-secondary text-xl mx-auto"
            type="button"
            onClick={resetGuests}
          >
            {translate("new-search")}
          </Button>
        )}
        <span className="my-12 border border-secondary" />
        {isLoadingLookup && <Loading />}
        {guests.rsvps.length > 0 && (
          <div className="mx-auto w-11/12 xl:w-1/2">
            <RSVPForm
              guests={guests}
              isLoading={isLoadingRSVP}
              onSubmit={handleRSVPSubmit}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
};

export default RSVP;
