import React, { FormEvent, useState } from "react";

import { Guest, Guests } from "../../util/interfaces";
import Loading from "../Loading";
import GuestData from "./GuestData";
import { useRSVPContext } from "../../context/rsvp-context";
import Button from "../Button";
import { stripTags, validateEmail } from "../../util/helpers";
import { useLanguageContext } from "../../context/language-context";

interface Props {
  guests: Guests;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}

const RSVPForm = ({ guests, isLoading, onSubmit }: Props) => {
  const [localGuests, setLocalGuests] = useState(guests);
  const [errors, setErrors] = useState({
    email: "",
    children: "",
    comments: "",
  });
  const { editRSVP, setEditRSVP } = useRSVPContext();
  const { translate } = useLanguageContext();

  const handleGuestDataChange = (index: number, updatedGuest: Guest) => {
    const updatedInvitees = localGuests.rsvps.map((guest, i) => {
      if (i === index) {
        return { ...guest, ...updatedGuest };
      }
      return guest;
    });
    setLocalGuests({ ...localGuests, rsvps: updatedInvitees });
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement
    >,
  ) => {
    const { name, value } = event.target;

    if (name === "children") {
      const newValue = parseInt(value, 10);

      setLocalGuests((prev) => ({
        ...prev,
        children_count: newValue,
        children: !!newValue,
      }));
    } else {
      setLocalGuests((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const comments = stripTags(localGuests.note);
    const emailValid = localGuests.rsvper_email
      ? validateEmail(localGuests.rsvper_email.trim())
      : true;
    const childrenValid = localGuests.children_count >= 0;
    const commentsValid = comments.length <= 256;

    if (!emailValid || !childrenValid || !commentsValid) {
      setErrors({
        email: emailValid ? "" : "Invalid email",
        children: childrenValid ? "" : "Invalid children",
        comments: commentsValid ? "" : "Invalid comments",
      });

      setLocalGuests((prev) => ({
        ...prev,
        note: comments,
      }));

      return;
    }

    setErrors({
      email: "",
      children: "",
      comments: "",
    });
    setLocalGuests((prev) => ({
      ...prev,
      note: comments,
    }));
    onSubmit(event);
  };

  return (
    <>
      {!editRSVP && (
        <div className="mb-8 rounded-md bg-secondary p-4">
          <p
            className="whitespace-pre-wrap p-2 text-center font-bold text-white"
            dangerouslySetInnerHTML={{ __html: translate("rsvp-found") }}
          />

          <Button
            classes="flex mx-auto text-xl px-8"
            onClick={() => setEditRSVP(true)}
          >
            {translate("rsvp-edit")}
          </Button>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="mx-auto mb-12 flex w-full flex-col"
      >
        {guests.rsvps.map((guest, index) => (
          <GuestData
            key={index}
            index={index}
            guest={guest}
            onGuestDataChange={(updatedGuest) =>
              handleGuestDataChange(index, updatedGuest)
            }
            disabled={!editRSVP}
          />
        ))}
        <span className="mb-4 border border-primary" />
        <div className="space-y-2">
          <label htmlFor="rsvper_email" className="block space-x-4">
            <span>{translate("email")}</span>
            {!editRSVP ? (
              <span className="inline-block rounded-md bg-linen p-2 font-bold shadow-inner">
                {localGuests.rsvper_email}
              </span>
            ) : (
              <input
                name="rsvper_email"
                title="Email"
                className="form-input w-1/2 focus:border-tertiary focus:ring-1 focus:ring-tertiary disabled:cursor-not-allowed"
                type="email"
                required
                placeholder="john@example.com"
                value={localGuests.rsvper_email || ""}
                onChange={handleChange}
                disabled={!editRSVP}
              />
            )}
            {errors.email && (
              <span className="font-bold text-secondary">{errors.email}</span>
            )}
          </label>
          <label htmlFor="children" className="block space-x-4">
            <span>{translate("children")}</span>
            {!editRSVP ? (
              <span className="inline-block rounded-md bg-linen p-2 font-bold shadow-inner">
                {localGuests.children ? localGuests.children_count : 0}
              </span>
            ) : (
              <select
                name="children"
                title="children"
                className="form-select disabled:cursor-not-allowed"
                value={localGuests.children ? localGuests.children_count : 0}
                onChange={handleChange}
                disabled={!editRSVP}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
              </select>
            )}
            {errors.children && (
              <span className="font-bold text-secondary">
                {errors.children}
              </span>
            )}
          </label>
          <label htmlFor="comments" className="block">
            <span className="text-gray-700">{translate("notes")}</span>
            {!editRSVP ? (
              <span className="block rounded-md bg-linen p-2 font-bold shadow-inner">
                {localGuests.note}
              </span>
            ) : (
              <textarea
                name="comments"
                title="comments"
                className="form-textarea mt-1 block max-h-36 w-full disabled:cursor-not-allowed"
                rows={5}
                placeholder="..."
                defaultValue={localGuests.note}
                onChange={handleChange}
                maxLength={256}
                disabled={!editRSVP}
              />
            )}
            {errors.comments && (
              <span className="font-bold text-secondary">
                {errors.comments}
              </span>
            )}
          </label>
        </div>
        {editRSVP && (
          <button
            type="submit"
            className="btn-primary mt-4 w-1/2 px-4 disabled:cursor-not-allowed disabled:bg-linen"
          >
            {isLoading ? <Loading /> : translate("confirm")}
          </button>
        )}
      </form>
    </>
  );
};

export default RSVPForm;
