import { useLanguageContext } from "../../context/language-context";

const Story = () => {
  const { translate } = useLanguageContext();

  return (
    <div className="my-16 flex flex-col space-y-4 p-2 px-8 text-center xl:my-0 xl:max-w-md xl:space-y-4 xl:text-lg/6">
      <h1>{translate("our-story-title")}</h1>
      <p>{translate("our-story-paragraphOne")}</p>
      <p>{translate("our-story-paragraphTwo")}</p>
      <p>{translate("our-story-paragraphThree")}</p>
    </div>
  );
};

export default Story;
