const StoryContainer = ({ children }) => {
  return (
    <div className="mx-auto my-12 flex">
      <div className="flex flex-col items-center pb-4 xl:flex-row xl:space-x-12 xl:pb-0">
        {children}
      </div>
    </div>
  );
};

export default StoryContainer;
