// import pujolImage from "../assets/images/Recommendations/Restaurants/Pujol.heic";
import agrotabernaImage from "../assets/images/Recommendations/Restaurants/Agrotaberna.webp";
import baltraBarImage from "../assets/images/Recommendations/Restaurants/BaltraBar.webp";
import bouImage from "../assets/images/Recommendations/Restaurants/Bou.webp";
import cafeDeNadieImage from "../assets/images/Recommendations/Restaurants/CafeDeNadie.webp";
import cancinoImage from "../assets/images/Recommendations/Restaurants/Cancino.webp";
import constelaImage from "../assets/images/Recommendations/Restaurants/Constela.webp";
import contramarImage from "../assets/images/Recommendations/Restaurants/Contramar.webp";
import drunkendogImage from "../assets/images/Recommendations/Restaurants/Drunkendog.webp";
import jennisImage from "../assets/images/Recommendations/Restaurants/Jennis.webp";
import kuraImage from "../assets/images/Recommendations/Restaurants/Kura.webp";
import laClandestinaImage from "../assets/images/Recommendations/Restaurants/LaClandestina.webp";
import mercadoRomaImage from "../assets/images/Recommendations/Restaurants/MercadoRoma.webp";
import miCompaImage from "../assets/images/Recommendations/Restaurants/MiCompa.webp";
import rayoImage from "../assets/images/Recommendations/Restaurants/Rayo.webp";
import siembraImage from "../assets/images/Recommendations/Restaurants/Siembra.webp";
import wallaceImage from "../assets/images/Recommendations/Restaurants/Wallace.webp";

export const RECOMMENDATIONS_MAP =
  "https://www.google.com/maps/d/edit?mid=1Rs4szKO_iM_GrItEBLDnE8H-syMP1n4&usp=sharing";

export enum RECOMMENDATION_CATEGORIES {
  Restaurants = "restaurants",
  Coffees = "coffees",
  Bars = "bars",
  Experiences = "experiences",
  Museums = "museums",
  Trips = "trips",
}

export const RECOMMENDATIONS_TITLES = {
  restaurants: "Restaurants",
  coffees: "Coffee & Dessert",
  bars: "Bars",
  experiences: "Experiences",
  museums: "Museums",
  trips: "Trips",
};

export const RECOMMENDATIONS = {
  restaurants: [
    {
      name: "Pujol",
      url: "https://pujol.com.mx",
      image: "https://live.staticflickr.com/4767/40596161711_a77a62d380_b.jpg",
      cost: "$$$$",
      note: "Just got awarded 2 Michelin stars!",
      serviceOption: "Reservations required",
      area: "Polanco",
      address:
        "Tennyson 133, Polanco, Polanco IV Secc, Miguel Hidalgo, 11570 Ciudad de México, CDMX, Mexico",
      rating: 4.7,
    },
    {
      name: "Contramar",
      url: "https://www.contramar.com.mx/",
      image: contramarImage,
      cost: "$$$",
      note: "Amazing seafood",
      serviceOption:
        "Reservations required * if you are unable to get a reservation we recommend going to their sister restaurant Entremar!",
      area: "Roma Norte",
      address:
        "Calle de Durango 200, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.9,
    },
    {
      name: "Expendio de Maiz Sin Nombre",
      url: "https://www.instagram.com/exp_maiz",
      image: "",
      cost: "$$",
      note: "Super unique dining experience! No menu, just great vibes/food! Your meal is based on three questions: 'How hungry are you?', 'Do you have any food allergies?', 'What do you like?'",
      serviceOption: "Cash-only, Doesn't accept reservations",
      area: "Roma Norte",
      address:
        "Av. Yucatan 84, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.3,
    },
    {
      name: "La Casa de Toño",
      url: "https://lacasadetono.com.mx/",
      image: "",
      cost: "$",
      note: "Great and inexpensive breakfast! The portions are very large.",
      serviceOption: "Doesn't accept reservations",
      area: "Located all over the city!",
      address:
        "Londres 144, Cuauhtémoc, Juárez, Cuauhtémoc, 06600 Ciudad de México, CDMX, Mexico",
      rating: 4.5,
    },
    {
      name: "Taquería Orinoco",
      url: "https://taqueriaorinoco.com/",
      image: "",
      cost: "$",
      note: "We recommend the Tacos de Chicharron!",
      serviceOption: "Doesn't accept reservations",
      area: "Roma Norte",
      address:
        "Av. Álvaro Obregón 179, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.4,
    },
    {
      name: "San Ángel Inn",
      url: "https://www.sanangelinn.com/",
      image: "",
      cost: "$$$",
      note: "Amazing brunch! We recommended heading to the Bazaar Sabado if you go on a Saturday!",
      serviceOption: "Reservations available not required",
      area: "San Angel",
      address:
        "Diego Rivera 50, San Ángel Inn, Álvaro Obregón, 01060 Ciudad de México, CDMX, Mexico",
      rating: 4.6,
    },
    {
      name: "Mercado Roma",
      url: "http://mercadoroma.com/",
      image: mercadoRomaImage,
      cost: "$$",
      note: "Multi-level food hall with international eats & a rooftop beer garden.",
      serviceOption: "n/a",
      area: "Roma Norte",
      address:
        "C. Querétaro 225, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.3,
    },
    {
      name: "Siembra Comedor",
      url: "https://siempresiembra.com.mx/",
      image: siembraImage,
      cost: "$$",
      note: "Very yummy trendy breakfast!",
      serviceOption: "Reservations available not required",
      area: "Polanco",
      address:
        "Av. Ejército Nacional Mexicano 314, Chapultepec Morales, Polanco V Secc, Miguel Hidalgo, 11560 Ciudad de México, CDMX, Mexico",
      rating: 4.1,
    },
    {
      name: "Cancino Pizzeria",
      url: "https://www.cancino.mx/",
      image: cancinoImage,
      cost: "$$",
      note: "Great pizzas!",
      serviceOption: "Reservations available not required",
      area: "Roma Norte",
      address:
        "Zacatecas 98, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.5,
    },
    {
      name: "Jenni's Quesadillas",
      url: "https://www.google.com/maps/place/Jenni%E2%80%99s+Quesadillas/@19.4194129,-99.1640846,15z/data=!4m6!3m5!1s0x85d1ff3a9c183333:0x3c2f53f32906ed92!8m2!3d19.4204652!4d-99.1579477!16s%2Fg%2F11ggtgjr00?entry=ttu",
      image: jennisImage,
      cost: "$",
      note: "Awesome stall for quesadillas in Roma Norte! Be prepared for a wait",
      serviceOption: "Cash-only, street stand",
      area: "Roma Norte",
      address:
        "Merida 83, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.6,
    },
    {
      name: "Izakaya Kura",
      url: "https://www.instagram.com/izakaya_kura/?hl=en",
      image: kuraImage,
      cost: "$$",
      note: "Great spot serving Japanese dishes such as sushi, ramen & oden!",
      serviceOption: "Reservations available not required",
      area: "Roma Norte",
      address:
        "Colima 378-Local A, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.5,
    },
    {
      name: "Mi Compa Chava Marisquería",
      url: "https://www.instagram.com/micompachava/?hl=en",
      image: miCompaImage,
      cost: "$$",
      note: "A fun marisquería where you can eat giant plates of seafood. Be prepared for a wait.",
      serviceOption: "Walk in only",
      area: "Roma Norte",
      address:
        "Zacatecas 172, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.3,
    },
  ],
  coffees: [
    {
      name: "Panaderia Rosetta",
      url: "https://www.instagram.com/panaderiarosetta",
      image: "",
      note: "Really worth the hype, they have the BEST guava roll. Go when the line isn't so long",
      serviceOption: "Walk in only",
      area: "Roma Norte",
      address:
        "Colima 179, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.5,
    },
    {
      name: "Brew Brew",
      url: "https://www.instagram.com/brewbrew.cafe/?hl=en",
      image: "",
      note: "Great boozy coffees!",
      area: "Roma Norte",
      address:
        "Cda. Orizaba 96, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.1,
    },
    {
      name: "Churrería El Moro",
      url: "https://elmoro.mx/",
      image: "",
      note: "Great churros! They are located all over the city, however we think this one downtown is the best!",
      area: "Centro Historico",
      address:
        "Eje Central Lázaro Cárdenas 42, Centro Histórico de la Cdad. de México, Centro, Cuauhtémoc, 06000 Ciudad de México, CDMX, Mexico",
      rating: 4.5,
    },
    {
      name: "Constela Cafe",
      url: "https://constelacafe.com/",
      image: constelaImage,
      note: "Sit outside and people watch over great coffee",
      area: "Roma Norte",
      address:
        "Colima 209, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.1,
    },
    {
      name: "Bou",
      url: "https://www.instagram.com/bou_mx_/?hl=en",
      image: bouImage,
      note: "Go here for yummy pastries and coffee",
      area: "Condesa",
      address:
        "Tonalá 110, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.3,
    },
  ],
  bars: [
    {
      name: "Departamento",
      url: "", // TODO: Better to leave blank or remove completely for rendering??
      image: "",
      note: "Bar with three floors open on a saturday night with different vibes and DJs on each floor",
      area: "Roma Norte",
      address:
        "Av. Álvaro Obregón 154-primer piso, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.1,
    },
    {
      name: "Rayo Cocktail Bar",
      url: "",
      image: rayoImage,
      note: "Very cool/unique drinks and ambience",
      area: "Between Condesa & Roma Norte",
      address:
        "C. Salamanca 85, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.4,
    },
    {
      name: "Agrotaberna Monstruo de Agua",
      url: "",
      image: agrotabernaImage,
      note: "Taproom for a cool axolotl themed beer we enjoy! They also serve food!",
      area: "Between Condesa & Roma Norte",
      address:
        "Ámsterdam 46, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX, Mexico",
      rating: 4.7,
    },
    {
      name: "Cafe de Nadie",
      url: "",
      image: cafeDeNadieImage,
      note: "Vibey funky cocktail bar with vinyl DJs",
      area: "Roma Norte",
      address:
        "Chihuahua 135, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
      rating: 4.1,
    },
    {
      name: "La Clandestina",
      url: "",
      image: laClandestinaImage,
      note: "Small and intimate bar",
      area: "Condesa",
      address:
        "Av. Álvaro Obregón 298, Colonia Condesa, Cuauhtémoc, 06140 Ciudad de México, CDMX, Mexico",
      rating: 4.4,
    },
    {
      name: "Baltra Bar",
      url: "",
      image: baltraBarImage,
      note: "Laid-back bar offering creative cocktails",
      area: "Condesa",
      address:
        "Iztaccihuatl 36D, Colonia Condesa, Cuauhtémoc, 06100 Ciudad de México, CDMX, Mexico",
      rating: 4.5,
    },
    {
      name: "Drunkendog",
      url: "",
      image: drunkendogImage,
      note: "Wide selection of craft beer",
      area: "Condesa",
      address:
        "Av. Nuevo León 4A, Colonia Condesa, Cuauhtémoc, 06140 Ciudad de México, CDMX, Mexico",
      rating: 4.6,
    },
    {
      name: "Wallace Whiskey Bar",
      url: "",
      image: wallaceImage,
      note: "Great DJ, cute dance floor",
      area: "Condesa",
      address:
        "Av. Tamaulipas 45, Colonia Condesa, Cuauhtémoc, 06140 Ciudad de México, CDMX, Mexico",
      rating: 4.5,
    },
  ],
  experiences: [
    {
      name: "LUCHA LIBRE",
      url: "https://www.tripadvisor.com/Attraction_Review-g150800-d2254266-Reviews-Arena_Mexico-Mexico_City_Central_Mexico_and_Gulf_Coast.html",
      image:
        "https://cdn2.picryl.com/photo/2017/03/04/lucha-libre-unsplash-d398d9-1024.jpg",
      description:
        "Must go and take us with you! Splurge on the better seats, you won't regret it!",
    },
    {
      name: "Centro Histórico",
      note: "Where el Zocalo, Templo Mayor, Cathedral and Bellas Artes are all located",
    },
    {
      name: "Parque Chapultepec",
      note: "A large, beautiful park with a number of museums within including the Castillo de Chapultepec. The Central Park of Mexico City!",
    },
    {
      name: "Markets!",
      note: "There are many artisanal markets in Mexico City. - Mercado de Artesanías La Ciudadela - BAZAAR Sabado- market with beautiful handcrafted art, only open on saturdays! - Bazar Resiliente",
    },
    {
      name: "Coyoacan",
      note: "Artsy neighborhood with art markets, Frida Kahlo's house/museum - Buy tickets in advance if you want to go to Casa Azul",
    },
    {
      name: "Bellas Artes",
      note: "Try to go see a show here, they haven't released the schedule for November yet but I will keep you posted!",
    },
    {
      name: "Basílica de Guadalupe",
      note: "Whether you find spiritual fulfillment or just want to admire the impressive architecture, everyone can enjoy a trip to this church.",
    },
    { name: "Casa bosques", details: "bookstore with a cool custom perfumery" },
  ],
  museums: [
    {
      name: "Museo Nacional de Antropología",
      url: "https://mna.inah.gob.mx",
      image:
        "https://images.rawpixel.com/image_800/cHJpdmF0ZS9zdGF0aWMvaW1hZ2Uvd2Vic2l0ZS8yMDIyLTA0L2xyL2JzNjY0NS1pbWFnZS1rd3Z4M2NvOC5qcGc.jpg",
      description:
        "One of the best museums in Mexico, focused on archaeology and amazing ancient artifacts.",
    },
    {
      name: "Museo Soumaya",
      note: "Huge popular museum next to Museo Jumex  It is a varied and eclectic assembly of art and collectibles of all sorts. ",
    },
    {
      name: "Museo Jumex",
      note: "Free contemporary art museum with original pieces from Damien Hirst, Andy Warhol, Gabriel Orozco, Jeff Koons, and Marcel Duchamp and many more",
    },
  ],
  trips: [
    {
      name: "Teotihuacan Pyramids",
      url: "https://teotihuacan.com",
      image: "https://live.staticflickr.com/5827/20686684795_52ac26f82c_b.jpg",
      description:
        "Site of ancient ruins with enormous pyramids and beautiful preserved structures.",
    },
    {
      name: "Puebla",
      note: "A small neighboring city, the birthplace of Mole with beautiful architecture and a volcanic backdrop - one to two day trip",
    },
    {
      name: "Xochilimco",
      note: "45 minutes south of the city by car, these 'floating gardens' are a UNESCO World Heritage site where you will find the last remnants of a vast water transport system built by the Aztecs.",
    },
  ],
};
