import { useLanguageContext } from "../context/language-context";
import Button from "./Button";

const FloatingButton = () => {
  const { setLanguage, language } = useLanguageContext();

  const toggleLanguage = () => {
    setLanguage((prev) => (prev === "en" ? "es" : "en"));
  };
  return (
    <Button
      onClick={toggleLanguage}
      className="fixed bottom-4 right-4 rounded-full bg-tertiary p-3 shadow-md hover:bg-accent-primary focus:ring-2 focus:ring-secondary xl:hidden"
    >
      {language === "en" ? "⚽" : "🏈"}
    </Button>
  );
};

export default FloatingButton;
