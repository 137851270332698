import eventPointOne from "../../assets/images/Home/HomeEventPointOne.webp";
import eventPointTwo from "../../assets/images/Home/HomeEventPointTwo.webp";
import joiImage from "../../assets/images/Home/Joi.webp";
import mikaImage from "../../assets/images/Home/Mika.webp";
import { useLanguageContext } from "../../context/language-context";
import Image from "../Image";

const EventPoints = () => {
  const { translate } = useLanguageContext();

  return (
    <div className="mb-8 flex flex-col xl:flex-row">
      <Point>
        <div className="flex xl:w-1/2">
          <Image
            src={eventPointTwo}
            alt="Cat and Beto standing leaning on a wall at a street corner"
          />
        </div>
        <div className="flex flex-col items-center justify-between bg-primary pb-4 text-center xl:w-1/2">
          <h2 className="px-4 pt-8 text-white xl:text-5xl">
            {translate("home-celebrate")}
          </h2>
          <Image classes="w-3/5" src={joiImage} alt="Joi happily sitting" />
        </div>
      </Point>
      <Point>
        <div className="flex xl:w-1/2">
          <Image
            src={eventPointOne}
            alt="Beto and Cat holding hands in front of an iron door"
          />
        </div>
        <div className="flex flex-col items-center justify-between bg-primary pb-4 text-center xl:w-1/2">
          <h2 className="px-8 pt-8 text-5xl text-white">
            {translate("home-date")}
          </h2>
          <Image
            classes="w-3/4 self-end"
            src={mikaImage}
            alt="Mika standing, happy, side profile"
          />
        </div>
      </Point>
    </div>
  );
};

const Point = ({ children }) => {
  return <div className="flex flex-col xl:w-1/2 xl:flex-row">{children}</div>;
};

export default EventPoints;
