import React, { useState } from "react";

import { Guest } from "../../util/interfaces";
import { useLanguageContext } from "../../context/language-context";

interface Props {
  guest: Guest;
  index: number;
  disabled: boolean;
  onGuestDataChange: (updatedGuest: Guest) => void;
}

const GuestData = ({ guest, index, disabled, onGuestDataChange }: Props) => {
  const { translate } = useLanguageContext();
  const [guestData, setGuestData] = useState<Guest>(guest);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    // Convert value if necessary
    const parsedValue = name.includes("rsvp") ? value === "Yes" : value;

    const updatedGuestData = {
      ...guestData,
      [name.split("_")[0]]: parsedValue,
    };

    setGuestData(updatedGuestData);
    onGuestDataChange(updatedGuestData);
  };

  const nameDisplay = (
    <span className="text-4xl">{`${guestData.first_name} ${guestData.last_name}`}</span>
  );

  const staticDisplay = (
    <span className="rounded-md bg-linen p-2 font-bold shadow-inner">
      {guestData.rsvp ? translate("yes") : translate("no")}
    </span>
  );
  const selectDisplay = (
    <select
      name={`rsvp_${index}`}
      title="RSVP"
      className="form-select"
      value={guestData.rsvp ? "Yes" : "No"}
      onChange={handleChange}
      disabled={disabled}
    >
      <option value="Yes">{translate("yes")}</option>
      <option value="No">{translate("no")}</option>
    </select>
  );

  return (
    <div className="mb-4 flex flex-col justify-center">
      <h1 className="flex text-2xl xl:text-3xl">{nameDisplay}</h1>
      <div className="flex flex-col xl:flex-row">
        <label
          htmlFor={`rsvp_${index}`}
          className="my-2 space-x-4 xl:space-x-8"
        >
          <span>{translate("attending")}</span>
          {disabled ? staticDisplay : selectDisplay}
        </label>
      </div>
    </div>
  );
};

export default GuestData;
