const Loading = () => {
  return (
    <div className="flex items-center justify-center">
      <svg className="h-10 w-10 animate-spin" viewBox="0 0 100 100">
        <circle
          fill="none"
          strokeWidth={14}
          className="stroke-black opacity-40"
          cx={50}
          cy={50}
          r={40}
        />
        <circle
          fill="none"
          strokeWidth={10}
          className="stroke-black"
          strokeDasharray={270}
          strokeDashoffset={180}
          cx={50}
          cy={50}
          r={40}
        />
      </svg>
    </div>
  );
};

export default Loading;
