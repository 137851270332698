export const HotelEN = {
  title: "Hotels",
  intro:
    "We have arranged discounted hotel rooms at the hotels listed below.\nRound-trip shuttle services will be provided to these two hotels on the day of the wedding, more information regarding transportation to come.",
  camino: "Camino Real Polanco",
  "camino-address": "Address: Calz. Gral. Mariano Escobedo 700, Anzures, Miguel Hidalgo, 11590 Ciudad de México, CDMX, Mexico",
  "camino-church": "20 minutes to the Church",
  "camino-venue": "25 minutes to the Venue",
  "camino-code": "Code: BODACATYHECT",
  "camino-date": "Date: November 7th to 9th/10th 2024",
  "camino-rate-title": "Special Rate",
  "camino-rate-one": "Luxury Room: $2,800.00 MXN",
  "camino-rate-two": "Luxury Room Superior Remodeled: $3,150.00 MXN",
  "camino-rate-three": "Garden View: $3,413.00 MXN",
  "camino-rate-note":
    "All guests will be able to make their own reservation by calling, emailing or going online.",
  "camino-rate-link":
    "Link to reservations: <a href=https://acortar.link/bk95Jf target='_blank' rel='noopener noreferrer'>Click here</a>",
  "camino-rate-email":
    "E-mail: <a href='mailto:reservas.mex@caminoreal.com.mx'>reservas.mex@caminoreal.com.mx</a>",
  "camino-rate-phone":
    "Phone number: <a href='tel:+525552277200'>55 5227 7200</a>",
  luca: "Hotel Luca",
  "luca-address": "Address: C. Orizaba 16, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
  "luca-church": "12 minute walk or 5 minute drive to the Church",
  "luca-venue": "30 minutes to the Venue",
  "luca-code": "Code: 'Boda Catalina y Roberto'",
  "luca-date": "Date: November 7th-9th/10th, 2024",
  "luca-rate-title": "Special Rate",
  "luca-rate-one": "Patio View Suite $ 2,650.00 MXN",
  "luca-rate-two": "Park View Suite $ 2,910.00 MXN",
  "luca-rate-three": "Interior View Suite $ 3,165.00 MXN",
  "luca-rate-four": "Terrace Suite $ 3,590.00 MXN",
  "luca-rate-note":
    "All guests will be able to make their own reservation by calling or emailing. Special rates are valid until August 31st",
  "luca-rate-note-two": "Breakfast is included!",
  "hotel-traffic": "NOTE: Travel times may be longer due to traffic",
  "hotel-note-one":
    "Keep in mind that shuttle services will be available only from the hotels mentioned above. If you'd like to explore other options, go ahead! You can book most hotels through any hotel booking website. If you'll be in the city for longer than the weekend, you may consider staying in an AirBnB for some, or all, of your stay. There are some very beautiful spaces available in the city at all price points.",
  "luca-rate-link":
    "Link to reservations: <a href=https://hotelluca.mx/en?currency=USD target='_blank' rel='noopener noreferrer'>Click here</a>",
  "luca-rate-email":
    "E-mail: <a href='mailto:reservaciones@hotelluca.mx'>reservaciones@hotelluca.mx</a>",
  "luca-rate-phone": "Phone Number: <a href='tel:+525559415300'>55 5941 5300</a>",
  "city-title": "Where else could we stay?",
  "city-info":
    "We recommend the neighborhoods of Roma Norte, Condesa, Polanco or Zona Rosa (La Reforma). If you have any doubts or questions please don't hesitate to send either of us a text. We are happy to help make your trip as easy and fun as possible in any way we can!",
  roma: "Roma Norte",
  "roma-note": "Young and Hip",
  "roma-description":
    "Roma Norte was designated as a Barrio Magico by the city—and the magic of this place will be instantly clear when you see Roma's vibrant Art Deco architecture. This is the area where our catholic mass will take place! (Parroqiua de La Sagrada Familia)\nHere, locals say you'll find a great food scene. La Roma is also known for its stellar cafes. You'll find a good one just by wandering around. We recommend going to Constela Cafe or Brew Brew if you're looking for a boozy coffee.",
  condesa: "Condesa",
  "condesa-note": "Chill, Authentic, Trendy",
  "condesa-description":
    "Roma and Condesa are sister neighborhoods with a shared history and their proximity puts them high on our list of places to visit in Mexico City. Like Roma, Condesa is flush with color, which makes this a gorgeous day trip or home base for visitors.\nPlus, its wide leafy boulevards also make Condesa feel far from the hustle and bustle of downtown (even though Centro Historico is only a few miles away). One of Condesa's main attractions, the gorgeous Parque Mexico, is filled with dogs, music, and plenty of benches to take it all in.",
  polanco: "Polanco",
  "polanco-note": "Bougie and Fun",
  "polanco-description":
    "Polanco is filled with high-end shopping, fancy cocktail bars, and some of the world's best restaurants. Visitors can explore the brilliant Museo Soumaya and the beloved Museo Nacional de Antropologia.\nPolanco is also intertwined with some of Mexico City's best parks, including a section of the truly astounding Chapultepec Park.",
  rosa: "Zona Rosa",
  "rosa-note": "aka La Reforma",
  "rosa-description":
    "During the day, Zona Rosa is a great place to go shopping, or as a destination to see one of the city's most famous monuments: El Angel (officially Monumento a la Independencia, or, basically, Angel of Independence). The monument was finished in 1910, which coincided with the 100-year anniversary of Mexico's independence from Spain.\nLocal Tip: Check out Little Seoul, Mexico City's Koreatown. It's located mostly in and around Zona Rosa.",
};

export const HotelES = {
  title: "Hoteles",
  intro:
    "Hemos reservado habitaciones con descuento en dos hoteles. Se proporcionarán servicios de transporte de ida y vuelta a estos hoteles el día de la boda. Más información sobre el transporte estará disponible próximamente.",
  camino: "Camino Real Polanco",
  "camino-address": "Address: Calz. Gral. Mariano Escobedo 700, Anzures, Miguel Hidalgo, 11590 Ciudad de México, CDMX, Mexico",
  "camino-church": "20 minutos a la Iglesia",
  "camino-venue": "25 minutos al Lugar de la Recepción",
  "camino-code": "Código: BODACATYHECT",
  "camino-date": "Fecha: del 7 al 9/10 de noviembre de 2024",
  "camino-rate-title": "Tarifa Especial",
  "camino-rate-one": "Habitación de Lujo: $2,800.00 MXN",
  "camino-rate-two": "Habitación de Lujo Superior Remodelada: $3,150.00 MXN",
  "camino-rate-three": "Vista al Jardín: $3,413.00 MXN",
  "camino-rate-note":
    "Todos los invitados podrán hacer su propia reserva llamando, enviando un correo electrónico o en línea.",
  "camino-rate-link":
    "Enlace directo para reservas: <a href=https://acortar.link/bk95Jf target='_blank' rel='noopener noreferrer'>aqui</a>",
  "camino-rate-email":
    "Correo electrónico: <a href='mailto:reservas.mex@caminoreal.com.mx'>reservas.mex@caminoreal.com.mx</a>",
  "camino-rate-phone":
    "Número de teléfono: <a href='tel:+525552277200'>55 5227 7200</a>",
  luca: "Hotel Luca",
  "luca-address": "Address: C. Orizaba 16, Roma Nte., Cuauhtémoc, 06700 Ciudad de México, CDMX, Mexico",
  "luca-church": "12 minutos caminando o 5 minutos en coche a la Iglesia",
  "luca-venue": "30 minutos al Lugar de la Recepción",
  "luca-code": "Código: 'Boda Catalina y Roberto'",
  "luca-date": "Fecha: del 7 al 9/10 de noviembre de 2024",
  "luca-rate-title": "Tarifa Especial",
  "luca-rate-one": "Suite con Vista al Patio: $2,650.00 MXN",
  "luca-rate-two": "Suite con Vista al Parque: $2,910.00 MXN",
  "luca-rate-three": "Suite con Vista Interior: $3,165.00 MXN",
  "luca-rate-four": "Suite con Terraza: $3,590.00 MXN",
  "luca-rate-note":
    "Las tarifas especiales son válidas hasta el 31 de Agosto. Todos los invitados podrán hacer su propia reserva llamando o enviando un correo electrónico.",
  "luca-rate-note-two": "El desayuno está incluido.",
  "luca-rate-link": 
    "Enlace para reservas: <a href=https://hotelluca.mx/en?currency=USD target='_blank' rel='noopener noreferrer'>Haga clic aquí</a>",
  "luca-rate-email": 
    "Correo electrónico: <a href='mailto:reservaciones@hotelluca.mx'>reservaciones@hotelluca.mx</a>",
  "luca-rate-phone": 
    "Número de teléfono: <a href='tel:+525559415300'>55 5941 5300</a>",
  "hotel-traffic":
    "NOTA: Los tiempos de viaje pueden ser más largos debido al tráfico",
  "hotel-note-one":
    "Ten en cuenta que los servicios de transporte solo estarán disponibles desde los hoteles mencionados anteriormente. Si deseas explorar otras opciones, ¡adelante! Puedes reservar la mayoría de los hoteles a través de cualquier sitio web de reservas. Si estarás en la ciudad por más tiempo que el fin de semana, podrías considerar alojarte en un AirBnB por una parte o la totalidad de tu estancia. Hay espacios muy hermosos disponibles en la ciudad a todos los precios.",
  "city-title": "¿Donde mas podriamos alojarnos en la Ciudad de Mexico?",
  "city-info":
    "Recomendamos las areas de Roma Norte, Condesa, Polanco o Zona Rosa (La Reforma). Si tienes alguna duda o pregunta, no dudes en enviarnos un mensaje de texto a cualquiera de nosotros. ¡Estamos felices de ayudar a que tu viaje sea lo más fácil y divertido posible en cualquier forma que podamos!",
  roma: "Roma Norte",
  "roma-note": "Joven y de Moda",
  "roma-description":
    "Roma Norte fue designado como un Barrio Mágico por la ciudad, y la magia de este lugar será instantáneamente clara cuando veas la vibrante arquitectura Art Deco de Roma. ¡Esta es el área donde se llevará a cabo nuestra misa católica! (Parroquia de La Sagrada Familia)\nAquí, los locales dicen que encontrarás una gran escena gastronómica! La Roma también es conocida por sus excelentes cafés. Encontrarás uno bueno simplemente caminando por la zona. Recomendamos ir a Constela Cafe o Brew Brew.",
  condesa: "Condesa",
  "condesa-note": "Auténtico y de Moda",
  "condesa-description":
    "Roma y Condesa son barrios hermanos con una historia compartida y su proximidad los coloca en lo alto de nuestra lista de lugares para visitar en la Ciudad de México. Al igual que Roma, Condesa está llena de color, lo que la convierte en una hermosa excursión de un día o base de operaciones para los visitantes.\nAdemás, sus amplios bulevares arbolados también hacen que Condesa se sienta lejos del bullicio del centro (aunque el Centro Histórico está a solo unos kilómetros de distancia). Una de las principales atracciones de Condesa, el hermoso Parque México, está lleno de perros, música y muchos bancos para disfrutar del entorno.",
  polanco: "Polanco",
  "polanco-note": "Elegante y Divertido",
  "polanco-description":
    "Polanco está lleno de tiendas de alta gama, bares de cócteles elegantes y algunos de los mejores restaurantes del mundo. Los visitantes pueden explorar el brillante Museo Soumaya y el Museo Nacional de Antropología.\nPolanco también está entrelazado con algunos de los mejores parques de la Ciudad de México, incluida una sección del verdaderamente asombroso Parque Chapultepec.",
  rosa: "Zona Rosa",
  "rosa-note": "también conocida como La Reforma",
  "rosa-description":
    "Durante el día, Zona Rosa es un excelente lugar para ir de compras, o como destino para ver uno de los monumentos más famosos de la ciudad: El Ángel (oficialmente Monumento a la Independencia). El monumento se terminó en 1910, coincidiendo con el centenario de la independencia de México de España.\nConsejo Local: Visita Little Seoul, el barrio coreano de la Ciudad de México. Está ubicado principalmente en y alrededor de Zona Rosa.",
};
