import React from "react";
import { IconContext } from "react-icons";
import { FaPassport } from "react-icons/fa";
import { useLanguageContext } from "../../context/language-context";

const PassportInfo = () => {
  const { translate } = useLanguageContext();
  return (
    <div className="flex flex-col bg-accent-secondary px-8 py-8 xl:h-60 xl:flex-row xl:px-12 xl:py-4">
      <div className="mb-4">
        <h1 className="py-2">{translate("travel-passport-title")}</h1>
        <div className="px-8 xl:px-16">
          <ul className="list-disc space-y-2">
            <li className="list-item">
              {translate("travel-passport-pointOne")}
            </li>
            <li className="list-item">
              {translate("travel-passport-pointTwo")}
            </li>
            <li
              dangerouslySetInnerHTML={{ __html: translate("travel-passport-pointThree"),}}
            />
          </ul>
        </div>
      </div>
      <IconContext.Provider value={{ size: "8rem" }}>
        <div className="flex items-center justify-center">
          <FaPassport />
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default PassportInfo;
