import { useLanguageContext } from "../context/language-context";

const Breadcrumbs = ({ page, id }) => {
  const { translate } = useLanguageContext();
  return (
    <div
      className="my-4 flex w-2/3 justify-center self-start xl:mb-6 xl:mt-0 xl:w-1/2"
      id={id}
    >
      <p>
        home&nbsp;/&nbsp;<b>{translate(page).toLowerCase()}</b>
      </p>
    </div>
  );
};

export default Breadcrumbs;
