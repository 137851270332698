interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  classes?: string;
}

const Button = ({
  classes = "",
  children,
  ...rest
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      className={`btn-primary disabled:hover:cursor-not-allowed ${classes}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
