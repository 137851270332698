import { useLanguageContext } from "../../context/language-context";

interface Props {
  event: string;
  end?: boolean;
}
const Event = ({ event, end = false }: Props) => {
  const { translate } = useLanguageContext();
  const key = event === "ceremony" ? "wedding-ceremony" : "wedding-reception";

  return (
    <div className={`${end ? "text-end" : undefined}`}>
      <h2 className="mb-2">{translate(`${key}-title`)}</h2>
      <h1>{translate(`${key}-location`)}</h1>
      <h2 className="text-2xl xl:text-3xl">{translate(`${key}-date`)}</h2>
      <h2 className="text-2xl xl:text-3xl">{translate(`${key}-time`)}</h2>
      <h2 className="text-pretty text-2xl xl:text-3xl">
        {translate(`${key}-details`)}
      </h2>
      <p className="mt-4">{translate(`${key}-description`)}</p>
    </div>
  );
};

export default Event;
