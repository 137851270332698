import Question from "./Question";

interface FAQItem {
  question: string;
  answer: string;
}

interface AccordionProps {
  FAQ: FAQItem[];
}

const Accordion = ({ FAQ }: AccordionProps) => {
  return (
    <section className="grid gap-4">
      {FAQ.map((faq, index) => {
        return <Question {...faq} key={index} />;
      })}
    </section>
  );
};

export default Accordion;
