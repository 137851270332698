const SectionTitle = ({ title }) => {
  return (
    <div className="flex flex-col justify-center">
      <h1 className="mt-4 p-4 capitalize " id={title}>
        {title}
      </h1>
    </div>
  );
};

export default SectionTitle;
