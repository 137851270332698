export const EMPTY_GUESTS = {
  party: -1,
  children: false,
  children_count: 0,
  rsvper_email: "",
  note: "",
  rsvps: [],
};

export const FIRESTORE = {
  INVITEES: "invitees",
  RSVP: "rsvp",
};
