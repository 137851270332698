import { useEffect, useState } from "react";

import countdownImage from "../../assets/images/Home/HomeCountdown.webp";
import { useLanguageContext } from "../../context/language-context";
import Image from "../Image";

let end = new Date("11/08/2024 4:0 PM");
// second * minute * hour * day
let _day = 1000 * 60 * 60 * 24;

const showRemaining = () => {
  let now = new Date();
  let distance = +end - +now;
  if (distance <= 0) {
    return "0";
  }

  let days = Math.floor(distance / _day);
  return days.toString();
};

const Countdown = () => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const { translate } = useLanguageContext();

  useEffect(() => {
    const daysLeft = showRemaining();
    setTimeRemaining(daysLeft);
  }, []);

  return (
    <div className="mx-auto my-12 flex w-11/12 flex-col items-center rounded-2xl bg-secondary py-12 xl:flex-row xl:justify-evenly">
      <Image
        src={countdownImage}
        alt="Polaroid of Beto and Cat embracing and laughing"
        classes="-rotate-2 shadow-button md:h-[32rem] lg:h-[28rem] xl:-rotate-3"
      />
      <div className="pt-4 text-center text-white xl:w-3/5">
        <h1
          id="countdown"
          className="mx-12 font-circular-med text-8xl font-bold"
        >
          {timeRemaining}
        </h1>
        <h2 className="font-circular-med lg:text-6xl">
          {translate("home-days")}
        </h2>
      </div>
    </div>
  );
};

export default Countdown;
