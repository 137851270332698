import { useRef } from "react";

import faqImage from "../assets/images/FAQ/FAQ.webp";
import Button from "../components/Button";
import Accordion from "../components/FAQ/Accordion";
import SectionButtons from "../components/FAQ/SectionButtons";
import SectionTitle from "../components/FAQ/SectionTitle";
import Image from "../components/Image";
import PageContainer from "../components/PageContainer";
import { useLanguageContext } from "../context/language-context";
import { QuestionsEN, QuestionsES } from "../data/questions";

export default function FAQ() {
  const { language, translate } = useLanguageContext();
  const sectionRefs = useRef({});
  const topRef = useRef<null | HTMLDivElement>(null);

  const scrollToSection = (key: string) => {
    if (key === "top" && topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (sectionRefs.current[key]) {
      sectionRefs.current[key].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const questionsToMap = language === "en" ? QuestionsEN : QuestionsES;

  return (
    <PageContainer page="faq">
      <div className="mx-auto flex max-w-6xl">
        <Image src={faqImage} alt="Beto, Cat, and the dogs on their porch" />
      </div>
      <div className="mx-4 mt-6 xl:mx-auto">
        <SectionButtons ref={topRef} onClick={scrollToSection} />
        {Object.keys(questionsToMap).map((key) => (
          <div
            key={key}
            className="m-auto w-full max-w-6xl scroll-mt-20"
            ref={(element) => (sectionRefs.current[key] = element)}
          >
            <SectionTitle title={key} />
            <Accordion FAQ={questionsToMap[key]} />
          </div>
        ))}

        <div className="m-2 flex justify-center">
          <Button onClick={() => scrollToSection("top")}>
            {translate("scroll-to-buttons")}
          </Button>
        </div>
      </div>
    </PageContainer>
  );
}
