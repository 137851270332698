interface Props {
  classes: string;
}
const StoryImageContainer = ({
  classes,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <div className="relative">
      <div
        className={`absolute -z-10 h-full w-full bg-primary opacity-90 ${classes}`}
      />
      {children}
    </div>
  );
};

export default StoryImageContainer;
