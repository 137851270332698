// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite"; // Using lite for only CRUD; If more functionality is needed we can adjust
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB4EVAHU5PfJmzJXd-6pBajZZXLMGWreGw",
  authDomain: "betoycaty-5cbcd.firebaseapp.com",
  projectId: "betoycaty-5cbcd",
  storageBucket: "betoycaty-5cbcd.appspot.com",
  messagingSenderId: "90053760932",
  appId: "1:90053760932:web:544b9edc5a54c49f0d9331",
  measurementId: "G-EDFSPRTJJY",
  // databaseURL: "https://betoycaty-5cbcd-default-rtdb.firebaseio.com/", // TODO: Remove after Realtime DB is deleted
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

const analytics = getAnalytics(app);
logEvent(analytics, "notification_received");
