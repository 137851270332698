import React from "react";
import { Route, Routes } from "react-router-dom";

import { RSVPProvider } from "../../context/rsvp-context";
import Recommendations from "../../views/Recommendations";
import Home from "../../views/Home";
import OurStory from "../../views/OurStory";
import Schedule from "../../views/Schedule";
import RSVP from "../../views/RSVP";
import Registry from "../../views/Registry";
import Travel from "../../views/Travel";
import Hotel from "../../views/Hotel";
import FAQ from "../../views/FAQ";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/our-story" element={<OurStory />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route
        path="/rsvp"
        element={
          <RSVPProvider>
            <RSVP />
          </RSVPProvider>
        }
      />
      <Route path="/registry" element={<Registry />} />
      <Route path="/travel" element={<Travel />} />
      <Route path="/hotel" element={<Hotel />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/recommendations" element={<Recommendations />} />
    </Routes>
  );
};

export default Navigation;
