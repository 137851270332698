import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/images/Logo.webp";
import { useLanguageContext } from "../../context/language-context";
import Button from "../Button";

export default function Navbar({ setFlyoutVisible, flyoutVisible }) {
  const navigate = useNavigate();
  const { translate, language, setLanguage } = useLanguageContext();

  const handleLanguageClick = () => {
    setLanguage((prev) => (prev === "en" ? "es" : "en"));
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="fixed top-0 z-10 h-20 w-full bg-white transition duration-500"
      id="main-nav"
    >
      <div className="my-2 block xl:hidden">
        <div className="flex items-center justify-around">
          <button
            className="m-4 flex min-w-10 flex-none flex-col hover:cursor-pointer xl:hidden"
            onClick={() => setFlyoutVisible(!flyoutVisible)}
          >
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </button>
          <h3 className="font-neville text-3xl" onClick={() => navigate("/")}>
            Caty y Beto
          </h3>
          <Button
            classes="px-4 py-2 flex-initial"
            onClick={() => navigate("/rsvp")}
          >
            RSVP
          </Button>
        </div>
      </div>
      <div className="flex items-center border-b-2 border-b-tertiary">
        <div className="ml-4 hidden h-20 w-full xl:flex">
          <img src={logo} alt="" />
          <h1
            className="flex items-center px-5 text-center font-neville text-black hover:cursor-pointer"
            onClick={() => navigate("/")}
          >
            Caty y Beto
          </h1>
          <div className="link-container" onClick={handleClick}>
            <Link className="link font-assistant" to="/our-story">
              los novios
            </Link>
          </div>
          <div className="link-container" onClick={handleClick}>
            <Link className="link font-assistant" to="/schedule">
              {translate("schedule").toLowerCase()}
            </Link>
          </div>
          <div className="link-container" onClick={handleClick}>
            <Link className="link font-assistant" to="/hotel">
              {translate("hotel").toLowerCase()}
            </Link>
          </div>
          <div className="link-container" onClick={handleClick}>
            <Link className="link font-assistant" to="/registry">
              {translate("registry").toLowerCase()}
            </Link>
          </div>
          <div className="link-container" onClick={handleClick}>
            <Link className="link font-assistant" to="/travel">
              {translate("travel").toLowerCase()}
            </Link>
          </div>
          <div className="link-container" onClick={handleClick}>
            <Link className="link font-assistant" to="/recommendations">
              {translate("things-to-do").toLowerCase()}
            </Link>
          </div>
          <div className="link-container" onClick={handleClick}>
            <Link className="link font-assistant" to="/faq">
              {translate("faq").toLowerCase()}
            </Link>
          </div>
        </div>
        <div className="link-container hidden xl:block">
          <Button
            classes="px-4 py-2 bg-secondary"
            onClick={handleLanguageClick}
          >
            {language === "en" ? "Español" : "English"}
          </Button>
        </div>
        <div className="link-container hidden xl:block">
          <Button
            classes="font-assistant px-6 py-3"
            onClick={() => navigate("/rsvp")}
          >
            RSVP
          </Button>
        </div>
      </div>
      <div className="h-3 w-full bg-gradient-to-r from-primary via-white to-secondary shadow shadow-black" />
    </div>
  );
}
