import { useLanguageContext } from "../../context/language-context";

const AirportInfo = () => {
  const { translate } = useLanguageContext();
  return (
    <div className="flex flex-col px-8 py-8 xl:h-96 xl:flex-row xl:px-12 xl:py-4">
      <div className="mb-4">
        <h1 className="py-2">{translate("travel-airport-title")}</h1>
        <div className="px-8 xl:px-16">
          <ul className="list-disc space-y-2">
            <li
              dangerouslySetInnerHTML={{
                __html: translate("travel-airport-pointOne"),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: translate("travel-airport-pointTwo"),
              }}
            />
            <li>{translate("travel-airport-pointThree")}</li>
            <li>{translate("travel-airport-pointFour")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AirportInfo;
