import { IconContext } from "react-icons";

import martiniImage from "../assets/images/Schedule/Martinis.png";
import mainImage from "../assets/images/Schedule/ScheduleMain.webp";
import mapImage from "../assets/images/Schedule/ScheduleMap.webp";
import Image from "../components/Image";
import PageContainer from "../components/PageContainer";
import Event from "../components/Schedule/Event";
import { useLanguageContext } from "../context/language-context";

// TODO: Fill in alt's for images
const Schedule = () => {
  const { translate } = useLanguageContext();

  return (
    <PageContainer page="schedule">
      <div className="mb-4 flex flex-col xl:mx-auto xl:max-w-7xl xl:flex-row xl:space-x-8">
        <div className="flex basis-2/5 flex-col space-y-4">
          <Image
            src={mainImage}
            alt="Beto and Cat smiling, holding hands in front of a blue house"
          />
          <Image
            src={mapImage}
            alt="Google Map image of Mexico City with stars on the Church and Venue"
          />
        </div>
        <div className="mt-8 flex basis-3/5 flex-col space-y-4">
          <div className="mx-auto flex flex-col rounded-md bg-primary px-8 py-4 text-white lg:w-4/5 xl:w-full">
            <div className="flex flex-col justify-between lg:flex-row">
              <IconContext.Provider value={{ size: "8rem" }}>
                <div className="flex items-center justify-center">
                  <Image
                    src={martiniImage}
                    alt="Martini glasses toasting"
                    classes="md:h-64 lg:h-56"
                  />
                </div>
              </IconContext.Provider>
              <div className="mt-6 flex flex-col xl:items-end">
                {/* <h2>{translate("schedule-drinks-title")}</h2> */}
                <h2
                  dangerouslySetInnerHTML={{ __html: translate("schedule-drinks-title") }}
                />
                <h2 className="text-2xl xl:text-3xl">
                  {translate("schedule-drinks-date")}
                </h2>
                <h2 className="text-2xl xl:text-3xl">6:00-9:00 p.m.</h2>
                <h2 className="text-3xl">{translate("schedule-drinks-dress")}</h2>
              </div>
            </div>
            <div className="mt-6 flex flex-col items-center">
              <p>{translate("schedule-drinks-details")}</p>
            </div>
          </div>
          <div className="flex flex-col space-y-12 rounded-md bg-secondary px-12 py-8 text-white lg:mx-24 xl:mx-0 xl:px-8">
            <Event event="ceremony" />
            <Event event="reception" end />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Schedule;
