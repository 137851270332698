import { useNavigate } from "react-router-dom";

import heroImage from "../../assets/images/Home/HomeHero.webp";
import { useLanguageContext } from "../../context/language-context";
import Button from "../Button";
import Image from "../Image";

const Hero = () => {
  const navigate = useNavigate();
  const { translate } = useLanguageContext();

  return (
    <div className="-mt-1 flex h-full flex-col-reverse items-center lg:m-auto lg:mt-8 lg:h-[48rem] lg:w-full lg:flex-row lg:px-0 lg:shadow-hero">
      <div className="mx-4 flex flex-col pb-4 pt-10 xl:w-1/3 xl:items-center xl:justify-center xl:py-0">
        <h2 className="pb-8 text-center text-5xl text-black xl:text-8xl">
          {translate("home-married")}
        </h2>
        <Button onClick={() => navigate("/schedule")}>
          <p className="text-2xl uppercase">{translate("home-details")}</p>
        </Button>
      </div>
      <div className="flex h-full items-center justify-center bg-primary xl:w-2/3 xl:overflow-hidden">
        <Image
          src={heroImage}
          alt="Polaroid of Cat and Beto holding each other"
          classes="w-11/12 xl:w-auto rotate-3 xl:h-[45rem] xl:rotate-6"
        />
      </div>
    </div>
  );
};

export default Hero;
