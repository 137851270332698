import { useState } from "react";
import { Link } from "react-router-dom";

import Button from "../Button";

export default function Flyout({ flyoutVisible, setFlyoutVisible }) {
  const [active, setActive] = useState("home");

  const linkColors = ["text-white bg-black", "text-black bg-white"];

  const handleMenuClick = (path: string) => {
    setActive(path);
    setFlyoutVisible(false);
  };

  return (
    <div
      className={`fixed left-0 top-20 z-10 flex h-full w-full bg-linen pt-8 transition duration-200 ease-in-out ${
        flyoutVisible
          ? "translate-x-0 opacity-100"
          : "-translate-x-full opacity-0"
      } xl:hidden`}
    >
      <div className="h-3/5 w-1/2 xl:hidden">
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("home")}
            className={`flyout-link ${
              active === "home" ? linkColors[0] : linkColors[1]
            }`}
            to="/"
          >
            home
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("our-story")}
            className={`flyout-link ${
              active === "our-story" ? linkColors[0] : linkColors[1]
            }`}
            to="/our-story"
          >
            our story
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("schedule")}
            className={`flyout-link ${
              active === "schedule" ? linkColors[0] : linkColors[1]
            }`}
            to="/schedule"
          >
            schedule
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("hotel")}
            className={`flyout-link ${
              active === "hotel" ? linkColors[0] : linkColors[1]
            }`}
            to="/hotel"
          >
            hotel
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("registry")}
            className={`flyout-link ${
              active === "registry" ? linkColors[0] : linkColors[1]
            }`}
            to="/registry"
          >
            registry
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("travel")}
            className={`flyout-link ${
              active === "travel" ? linkColors[0] : linkColors[1]
            }`}
            to="/travel"
          >
            travel
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("recommendations")}
            className={`flyout-link ${
              active === "recommendations" ? linkColors[0] : linkColors[1]
            }`}
            to="/recommendations"
          >
            things to do
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("faq")}
            className={`flyout-link ${
              active === "faq" ? linkColors[0] : linkColors[1]
            }`}
            to="/faq"
          >
            faq
          </Link>
        </div>
        <div className="flyout-link-container">
          <Link
            onClick={() => handleMenuClick("rsvp")}
            className={`flyout-link ${
              active === "rsvp" ? linkColors[0] : linkColors[1]
            }`}
            to="/rsvp"
          >
            rsvp
          </Link>
        </div>
      </div>
      <div className="mr-6 flex h-3/5 w-1/2 flex-col items-end space-y-12 xl:hidden">
        <Button
          classes="bg-tertiary font-bold text-black p-3 text-2xl/3"
          onClick={() => setFlyoutVisible(false)}
        >
          X
        </Button>
      </div>
    </div>
  );
}
