import { Suspense } from "react";

import Breadcrumbs from "./Breadcrumbs";
import Loading from "./Loading";

interface Props {
  page: string;
}

const PageContainer = ({ page, children }: React.PropsWithChildren<Props>) => {
  return (
    <div className="container mx-auto flex min-h-screen-88 flex-col pt-24 xl:pt-32">
      {page !== "home" && <Breadcrumbs page={page} id={page} />}
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </div>
  );
};

export default PageContainer;
