import caminoImage from "../assets/images/Hotel/CaminoRealPolanco.webp";
import flair from "../assets/images/Hotel/HotelFlair.png";
import lucaImage from "../assets/images/Hotel/Luca.webp";
import HotelCard from "../components/Hotel/HotelCard";
import Image from "../components/Image";
import PageContainer from "../components/PageContainer";
import { useLanguageContext } from "../context/language-context";
import { HotelEN, HotelES } from "../data/hotel";

const Hotel = () => {
  const { language } = useLanguageContext();

  const hotelInfo = language === "en" ? HotelEN : HotelES;

  return (
    <PageContainer page="hotel">
      <div className="mb-4 flex flex-col bg-tan">
        <div className="py-4">
          <div className="flex flex-col justify-evenly text-center xl:flex-row xl:px-12">
            <div className="pl-12 xl:-rotate-12">
              <Image
                src={flair}
                alt="Flair lines"
                classes="w-16 xl:w-full xl:h-32 xl:pt-1"
              />
            </div>
            <div className="flex flex-col justify-center px-8 xl:w-3/5 xl:px-12">
              <h1 className="text-white xl:text-5xl">{hotelInfo.title}</h1>
              <p
                className="whitespace-pre-wrap text-primary"
                dangerouslySetInnerHTML={{ __html: hotelInfo.intro }}
              />
            </div>
            <div className="rotate-180 pl-8 xl:rotate-12">
              <Image
                src={flair}
                alt="Flair lines"
                classes="w-16 xl:w-full xl:h-32 xl:scale-x-[-1]"
              />
            </div>
          </div>
          <div className="mt-8 flex flex-col px-8 xl:flex-row">
            <HotelCard>
              <Image
                src={caminoImage}
                alt="Photo of a pool at the Camino Real Polanco"
                classes="h-56 rounded-t-[4px] lg:h-[36rem] xl:h-[30rem]"
                url="https://www.caminoreal.com/caminoreal/camino-real-polanco-mexico"
              />
              <div className="p-4">
                <p className="font-assistant text-xl font-bold text-primary">
                  {hotelInfo.camino}
                </p>
                <p className="text-secondary font-bold">{hotelInfo["camino-address"]}</p>
                <p className="text-secondary">{hotelInfo["camino-church"]}</p>
                <p className="text-secondary">{hotelInfo["camino-venue"]}</p>
                <div className="mt-8 text-primary">
                  <p className="font-bold">{hotelInfo["camino-code"]}</p>
                  <p>{hotelInfo["camino-date"]}</p>
                  <p>{hotelInfo["camino-rate-title"]}</p>
                  <ul className="list-inside list-disc">
                    <li className="list-item">
                      {hotelInfo["camino-rate-one"]}
                    </li>
                    <li className="list-item">
                      {hotelInfo["camino-rate-two"]}
                    </li>
                    <li className="list-item">
                      {hotelInfo["camino-rate-three"]}
                    </li>
                  </ul>
                </div>
                <div className="mt-6 text-center text-primary">
                  <p>{hotelInfo["camino-rate-note"]}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["camino-rate-link"],
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["camino-rate-email"],
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["camino-rate-phone"],
                    }}
                  />
                </div>
              </div>
            </HotelCard>
            <HotelCard>
              <Image
                src={lucaImage}
                alt="Photo of a fountain around Hotel Luca"
                classes="h-56 rounded-t-[4px] lg:h-[36rem] xl:h-[30rem]"
                url="https://hotelluca.mx/es?currency=USD"
              />
              <div className="p-4">
                <p className="font-assistant text-xl font-bold text-primary">
                  {hotelInfo.luca}
                </p>
                <p className="text-secondary font-bold">{hotelInfo["luca-address"]}</p>
                <p className="text-secondary">{hotelInfo["luca-church"]}</p>
                <p className="text-secondary">{hotelInfo["luca-venue"]}</p>
                <div className="mt-8 text-primary">
                  <p className="font-bold">{hotelInfo["luca-code"]}</p>
                  <p>{hotelInfo["luca-date"]}</p>
                  <p>{hotelInfo["luca-rate-title"]}</p>
                  <ul className="list-inside list-disc">
                    <li className="list-item">{hotelInfo["luca-rate-one"]}</li>
                    <li className="list-item">{hotelInfo["luca-rate-two"]}</li>
                    <li className="list-item">
                      {hotelInfo["luca-rate-three"]}
                    </li>
                    <li className="list-item">{hotelInfo["luca-rate-four"]}</li>
                  </ul>
                </div>
                <div className="mt-6 text-center text-primary">
                  <p>{hotelInfo["luca-rate-note"]}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["luca-rate-email"],
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["luca-rate-phone"],
                    }}
                  />
                  <p>{hotelInfo["luca-rate-note-two"]}</p>
                </div>
              </div>
            </HotelCard>
          </div>
          <div className="mt-8 text-center font-bold text-black">
            <h2>*{hotelInfo["hotel-traffic"]}*</h2>
          </div>
          <div className="my-12 bg-secondary p-8 text-center text-white xl:mx-24">
            <h3>{hotelInfo["hotel-note-one"]}</h3>
          </div>
          <div className="m-8 mx-auto w-11/12 bg-white p-8 text-primary xl:w-9/12 xl:p-12">
            <h1 className="text-center">{hotelInfo["city-title"]}</h1>
            <div className="xl:px-8">
              <div className="flex justify-center">
                <p className="pb-8 pt-2 text-center xl:w-3/4">
                  {hotelInfo["city-info"]}
                </p>
              </div>
              <ul className="space-y-8">
                <li className="flex flex-col xl:flex-row xl:space-x-4">
                  <div className="text-center xl:w-96 xl:text-right">
                    <h2 className="font-acherone ">{hotelInfo.roma}</h2>
                    <h3>{hotelInfo["roma-note"]}</h3>
                  </div>
                  <p
                    className="w-fit whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["roma-description"],
                    }}
                  />
                </li>
                <li className="flex flex-col xl:flex-row xl:space-x-4">
                  <div className="text-center xl:w-96 xl:text-right">
                    <h2 className="font-acherone">{hotelInfo.condesa}</h2>
                    <h3>{hotelInfo["condesa-note"]}</h3>
                  </div>
                  <p
                    className="w-fit whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["condesa-description"],
                    }}
                  />
                </li>
                <li className="flex flex-col xl:flex-row xl:space-x-4">
                  <div className="text-center xl:w-96 xl:text-right">
                    <h2 className="font-bayon">{hotelInfo.polanco}</h2>
                    <h3>{hotelInfo["polanco-note"]}</h3>
                  </div>
                  <p
                    className="w-fit whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["polanco-description"],
                    }}
                  />
                </li>
                <li className="flex flex-col xl:flex-row xl:space-x-4">
                  <div className="text-center xl:w-96 xl:text-right">
                    <h2 className="font-cantora-one">{hotelInfo.rosa}</h2>
                    <h3>{hotelInfo["rosa-note"]}</h3>
                  </div>
                  <p
                    className="w-fit whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: hotelInfo["rosa-description"],
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Hotel;
