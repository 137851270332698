export const QuestionsEN = {
  rsvp: [
    {
      question: "What should I do if I can't make it?",
      answer:
        "If you can't make the event, just specify in your RSVP. If you previously RSVP'd yes and need to change it to a no please let us know. You can also do it through the RSVP flow like you did originally.",
    },
    {
      question: "When is the RSVP deadline?",
      answer:
        "September 30th, 2024\n\nIf you know before that date you will or will not make it please update your RSVP as soon as possible.",
    },
    {
      question: "Do I need to mail back my RSVP or can I do it online?",
      answer:
        "You can RSVP directly on this site. Just click the RSVP button in the top right corner of the navigation bar.",
    },
  ],
  wedding: [
    {
      question: "What is the attire?",
      answer:
        "CDMX is a bright and vibrant city, and we encourage clothing that reflects the same!\n\nThe attire is formal/black-tie optional.\n\nLadies can wear floor-length or midi length formal dresses. Gentlemen can wear a suit & tie. No jeans or white please.",
    },
    {
      question: "Are my kids invited?",
      answer:
        "Yes, however <b><u>PLEASE</u></b> let us know if your kids are coming so that we can order the correct amount of kid meals.",
    },
    {
      question: "Will the wedding be indoors or outdoors?",
      answer:
        "BOTH&hellip;kinda\nThe religious ceremony will take place inside <em>la Parroquia de la Sagrada Familia</em>\n\nThe cocktail hour will be held outdoors in the garden area of the <em>Museo Casa de La Bola</em>, weather permitting. The reception and dance will take place indoors.",
    },
    {
      question: "What is the weather like during this time of year?",
      answer:
        "It can be as high as 80&deg;F in the afternoon and drop below 50&deg;F at night. We highly suggest checking the weather closer to the event and consider bringing a jacket or shawl.",
    },
    {
      question: "Can I bring a plus one?",
      answer:
        "There are limitations on the number of guests we can accommodate. However, if you wish to bring a plus one, please reach out to us directly. We may be able to accommodate your request if space allows. If you believe there may have been a mistake in your RSVP, please don't hesitate to let us know!",
    },
  ],
  general: [
    {
      question: "Is Mexico City safe?",
      answer:
        "Yes! However, it's the most populous city in North America so you'll need to exercise the same caution you would in any other major city. Like any foreign travel, be mindful of your surroundings and look after your belongings.",
    },
    {
      question: "How is the cell &amp; Wi-Fi service?",
      answer:
        "Checking into your phone plans before traveling to Mexico City is a good idea. There are many places with WiFi, but you will most likely want to have it available on your cell phone while navigating the city.",
    },
    {
      question: "Can I drink the tap water?",
      answer:
        "We recommend to only drink bottled water; it is readily available everywhere you go. The food on the other hand is typically safe."
    },
    {
      question: "What day tours are near Mexico City?",
      answer:
        "There are a bunch! Including but not limited to Teotihuacan, Puebla, Cholula, San Miguel Allende, las Grutas de Tolantongo, and Xochimilco.\n\nCheck out Airbnb experiences or Viator if you are interested in guided tours!",
    },
    {
      question: "Do I need cash?",
      answer:
        "Mexico uses the Peso, which can be exchanged at the airport upon arrival or withdrawn from any ATM using your debit card. You should carry some cash for eating at the local markets, buying water from a street vendor, or purchasing crafts at the artisanal markets. Most sit-down restaurants accept credit card.",
    },
    {
      question: "How is traffic?",
      answer:
        "Distances may appear close, but traffic can get <b>very</b> heavy. We recommend always budgeting extra time to get anywhere via car. Especially the day of the wedding!",
    },
    {
      question: "I still have questions, what is the best way to contact you?",
      answer: "Please email us at joika.casa@gmail.com",
    },
  ],
};

export const QuestionsES = {
  "Confirmacion de asistencia (RSVP)": [
    {
      question: "¿Qué debo hacer si no puedo asistir?",
      answer:
        "Si no puedes asistir al evento, por favor especifícalo en tu confirmación de asistencia (RSVP).\n\nSi previamente confirmaste que sí asistirías y necesitas cambiar tu respuesta a un “no”, por favor avísanos. También puedes hacerlo a través del proceso de RSVP, como lo hiciste originalmente.",
    },
    {
      question: "¿Cuál es la última fecha para confirmar mi asistencia?",
      answer:
        "30 de septiembre del 2024\n\nSi sabes antes de esa fecha si asistirás o no, por favor actualiza tu RSVP lo más antes posible.",
    },
    {
      question:
        "¿Necesito enviar mi confirmación de asistencia por correo o puedo hacerlo en línea?",
      answer:
        "Puedes confirmar tu asistencia directamente en este sitio. Solo haz clic en el botón de RSVP en la esquina superior derecha de la barra de navegación.",
    },
  ],
  "la boda": [
    {
      question: "¿Cuál es el código de vestimenta?",
      answer:
        "CDMX es una ciudad vibrante, ¡y te reccomendamos a usar ropa que refleje lo mismo!\n\nEl código de vestimento es formal.\n\nLas damas pueden usar vestidos formales largos o midi. Los caballeros pueden usar traje y corbata. Por favor, no jeans ni ropa blanca.",
    },
    {
      question: "¿Mis hijos están invitados?",
      answer:
        "Sí, sin embargo, <b><u>POR FAVOR</u></b> avísanos si tus hijos asistirán para que podamos ordenar la cantidad correcta de comidas para niños.",
    },
    {
      question: "¿La boda será adentro o al aire libre?",
      answer:
        "AMBOS&hellip;más o menos\n\nLa ceremonia religiosa se llevará a cabo dentro de <em>la Parroquia de la Sagrada Familia</em>\n\nLa hora del cóctel se realizará al aire libre en el área del jardín del <em>Museo Casa de La Bola</em>, si el clima lo permite. La recepción y el baile se llevarán en la parte de adentro de Museo Casa de la Bola.",
    },
    {
      question: "¿Cómo es el clima durante esta época del año?",
      answer:
        "La temperatura puede llegar hasta 80&deg;F (27&deg;C) en la tarde y bajar a menos de 50&deg;F (10&deg;C) por la noche. Te sugerimos revisar el clima cerca de la fecha del evento y considerar llevar una chaqueta o chal.",
    },
    {
      question: "¿Puedo traer un acompañante?",
      answer:
        "Hay limitaciones en la cantidad de invitados que podemos acomodar. Sin embargo, si deseas traer un acompañante, por favor comunícate con nosotros directamente. Es posible que podamos acomodar tu solicitud si hay espacio disponible. Si crees que hubo un error en tu RSVP, ¡no dudes en informarnos!",
    },
  ],
  "Preguntas generales": [
    {
      question: "¿Es segura la Ciudad de México?",
      answer:
        "¡Sí! Sin embargo, es la ciudad más poblada de América del Norte, por lo que debes ejercer la misma precaución que en cualquier otra gran ciudad. Como en cualquier viaje al extranjero, ten en cuenta tu entorno y cuida tus pertenencias.",
    },
    {
      question: "¿Cómo es el servicio de celular y Wi-Fi?",
      answer:
        "Revisar tu plan de teléfono antes de viajar a la Ciudad de México es una buena idea. Hay muchos lugares con Wi-Fi, pero probablemente querrás tener acceso en tu teléfono mientras te desplazas por la ciudad.",
    },
    {
      question: "¿Puedo beber el agua del lavabo?",
      answer:
        "<b>¡No!</b> Por favor, no bebas el agua del grifo. La mayoría de los locales tampoco la bebe, por lo que el agua embotellada está disponible en todas partes. La comida, por otro lado, es generalmente segura; es más probable que te enfermes por el agua (al tragarla en la ducha, cepillarte los dientes con agua del grifo, etc.) que por la comida.\n\n¡Come en los carritos de la calle! Es una de las mejores (y nuestras favoritas) comidas de la ciudad. Solo busca lugares que estén ocupados. Los locales tampoco quieren enfermarse.\n\nSi te enfermas (puede pasar en cualquier lugar), compra 'Treda' en cualquier farmacia. Es un antidiarreico con un fuerte antibiótico (neomicina) que requiere receta en los EE.UU.",
    },
    {
      question: "¿Qué excursiones de un día hay cerca de la Ciudad de México?",
      answer:
        "¡Hay muchas! Incluyendo, pero no limitadas a Teotihuacán, Puebla, Cholula, San Miguel de Allende, las Grutas de Tolantongo y Xochimilco.\n\n¡Revisa las experiencias de Airbnb o Viator si estás interesado en tours guiados!",
    },
    {
      question: "¿Necesito efectivo?",
      answer:
        "México utiliza el peso, que se puede cambiar en el aeropuerto al llegar o retirar de cualquier cajero automático usando tu tarjeta de débito. Debes llevar algo de efectivo para comer en los mercados locales, comprar agua de un vendedor ambulante o adquirir artesanías en los mercados artesanales. La mayoría de los restaurantes con servicio de mesa aceptan tarjeta de crédito.",
    },
    {
      question: "Como esta el traffico?",
      answer:
        "Las distancias pueden parecer cortas, pero el tráfico puede ser <b>muy</b> intenso. Recomendamos siempre siempre considerar tiempo extra para llegar a cualquier lugar en coche. Especialmente el día de la boda! ",
    },
    {
      question:
        "Tengo más preguntas, ¿cuál es la mejor manera de contactarlos?",
      answer:
        "Por favor, envíanos un correo electrónico a joika.casa@gmail.com",
    },
  ],
};
