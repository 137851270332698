import {
  DocumentData,
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore/lite";
import { db } from "../firebase";

import { FIRESTORE } from "./constants";
import { Guests } from "./interfaces";

interface GuestDataResponse {
  editRSVP: boolean;
  data: Guests;
}

// Function to clean data
function cleanData(value: string): string {
  return value.trim().toUpperCase();
}

// TODO: Go through and refine
export const getGuestsDataByName = async (
  firstName: string,
  lastName: string,
): Promise<GuestDataResponse | null> => {
  const cleanedFirstName = cleanData(firstName);
  const cleanedLastName = cleanData(lastName);

  let editRSVP = true;
  const inviteesRef = collection(db, FIRESTORE.INVITEES);

  const q = query(
    inviteesRef,
    where("first_name", "==", cleanedFirstName),
    where("last_name", "==", cleanedLastName),
    limit(1),
  );

  console.log("Executing query for guests by name:", { firstName, lastName });

  const querySnap = await getDocs(q);
  if (querySnap.empty) {
    console.error("No invitee found");
    return null;
  }

  const partyID = querySnap.docs[0].get("party");

  try {
    // Get Party and RSVP Info
    const [inviteesResponse, rsvpResponse] = await Promise.all([
      getPartyByID(partyID),
      getRSVPByID(partyID),
    ]);

    if (!inviteesResponse) {
      console.error("Party data not found");
      return null;
    }

    // Use invitees data
    let data = { ...inviteesResponse, party: partyID };

    // If rsvp data exists, use that and set flag
    if (rsvpResponse) {
      editRSVP = false;
      data = { ...rsvpResponse, party: partyID };
    }

    return { editRSVP, data };
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return null;
  }
};

// TODO: Go through and refine
export const getGuestsDataByEmail = async (email: string) => {
  // const inviteesRef = collection(db, FIRESTORE.INVITEES);
  const rsvpRef = collection(db, FIRESTORE.RSVP);
  const q = query(rsvpRef, where("rsvper_email", "==", email), limit(1));

  console.log("Executing query for guests by email:", { email });

  const querySnap = await getDocs(q);

  if (querySnap.empty) {
    console.error("No rsvp found");
    return null;
  }
  const partyID = querySnap.docs[0].get("party");

  const rsvpData = transformRSVPData(querySnap.docs);
  const data = { ...rsvpData, party: partyID };

  return { editRSVP: true, data };
};

const getRSVPByID = (id: number) => {
  return fetchDocumentByID("rsvp", id, transformRSVPData);
};

// TODO: Look into why this is running twice
const getPartyByID = (id: number) => {
  return fetchDocumentByID("invitees", id, transformInviteeData);
};

const fetchDocumentByID = async (
  collectionName: string,
  id: number,
  transform,
) => {
  const q = query(collection(db, collectionName), where("party", "==", id));

  console.log(`Fetching document by ID in ${collectionName}:`, { id });

  const querySnap = await getDocs(q);

  if (!querySnap.empty) {
    const rawDoc = querySnap.docs;
    return transform(rawDoc);
  } else if (collectionName === "rsvp" && querySnap.empty) {
    // return {
    //   children: false,
    //   children_count: 0,
    //   note: "",
    //   rsvps: [],
    // };
    return null;
  } else {
    console.error(`Error: No document found in ${collectionName} with ID ${id}`);
    return undefined;
  }
};

const transformInviteeData = (docs: DocumentData[]) => {
  const rsvpsInit = docs.map((doc) => {
    const data = doc.data();
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      rsvp: false,
    };
  });

  const rsvpInit = {
    children: false,
    children_count: 0,
    rsvper_email: "",
    note: "",
    rsvps: rsvpsInit,
  };

  return rsvpInit;
};

const transformRSVPData = (docs: DocumentData[]) => {
  const doc = docs[0].data();
  return {
    children: doc.children,
    children_count: doc.children_count,
    rsvper_email: doc.rsvper_email,
    note: doc.note,
    rsvps: doc.rsvps,
  };
};

export const writeRSVPData = async (rsvpData: Guests) => {
  const q = query(collection(db, "rsvp"), where("party", "==", rsvpData.party));
  const querySnap = await getDocs(q);
  try {
    if (!querySnap.empty) {
      // Merge doc
      const docID = querySnap.docs[0].id;
      await setDoc(doc(db, "rsvp", docID), rsvpData, { merge: true });
    } else {
      // Create new doc
      console.log("No document found, creating a new one");
      await addDoc(collection(db, "rsvp"), rsvpData);
    }
    return true;
  } catch (error) {
    console.error("Failed to write RSVP data:", error);
    return false;
  }
};

export const writeEmailData = async (
  users: {
    first_name: string;
    last_name: string;
    email: string;
  }[],
) => {
  let isError = false;
  const inviteesRef = collection(db, "invitees");

  for (const user of users) {
    const q = query(
      inviteesRef,
      where("first_name", "==", user.first_name),
      where("last_name", "==", user.last_name),
      limit(1),
    );

    try {
      const querySnap = await getDocs(q);

      if (!querySnap.empty) {
        // Merge doc
        const docID = querySnap.docs[0].id;
        await setDoc(
          doc(db, "invitees", docID),
          { email: user.email },
          { merge: true },
        );
      } else {
        console.warn(`No document found for ${user.first_name} ${user.last_name}`);
      }
    } catch (error) {
      console.error("Failed to write email data:", error);
      isError = true;
    }
  }

  return isError;
};
