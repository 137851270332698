import { FormEvent, useState } from "react";
import { toast } from "react-toastify";

import { useRSVPContext } from "../context/rsvp-context";
import { EMPTY_GUESTS } from "./constants";
import {
  getGuestsDataByEmail,
  getGuestsDataByName,
  writeRSVPData,
} from "./firebase-firestore";
import { Guests } from "./interfaces";
import { stripTags } from "./helpers";
import { useLanguageContext } from "../context/language-context";

export const useGuestLookup = (resetGuests: () => void) => {
  const { translate } = useLanguageContext();
  const { setLookupFirstName, setLookupLastName, setLookupEmail, setEditRSVP } =
    useRSVPContext();
  const [isLoadingLookup, setIsLoadingLookup] = useState(false);
  const [guests, setGuests] = useState<Guests>(EMPTY_GUESTS);

  const handleLookupSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoadingLookup(true);
    resetGuests();

    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);
    const firstName = data.firstName.toString().trim();
    const lastName = data.lastName.toString().trim();
    const email = data.email.toString().trim();

    try {
      let result: { editRSVP: boolean; data: Guests } | null = null;

      // Name takes precedence in calls
      if (firstName && lastName) {
        result = await getGuestsDataByName(firstName, lastName);
      } else if (email) {
        result = await getGuestsDataByEmail(email);
      }

      if (!result) {
        toast.error(translate("guest-not-found"));
        return;
      } else {
        setGuests(result.data);
        setEditRSVP(result.editRSVP);
        toast.success(translate("guest-found"));
      }
    } catch (error) {
      toast.error(translate("data-fail"));
    } finally {
      setIsLoadingLookup(false);
    }

    // Clear LookupForm after processing request
    setLookupFirstName("");
    setLookupLastName("");
    setLookupEmail("");
  };

  return {
    guests,
    isLoadingLookup,
    setGuests,
    handleLookupSubmit,
  };
};

export const useRSVP = (guests: Guests, resetGuests: () => void) => {
  const { translate } = useLanguageContext();
  const { setLookupFirstName, setLookupLastName, setLookupEmail } =
    useRSVPContext();
  const [isLoadingRSVP, setIsLoadingRSVP] = useState(false);

  const handleRSVPSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoadingRSVP(true);

    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    const rsvps = guests?.rsvps.map((invitee, index) => {
      return {
        first_name: invitee.first_name,
        last_name: invitee.last_name,
        rsvp: data[`rsvp_${index}`] === "Yes",
      };
    });

    const rsvpData = {
      children: !!+data.children,
      children_count: +data.children,
      party: guests.party || +data.party,
      rsvper_email: data.rsvper_email.toString(),
      note: stripTags(data.comments.toString()),
      rsvps: rsvps,
    };

    // Write to firestore
    try {
      console.log("RSVP Data", rsvpData);
      await writeRSVPData(rsvpData);
      toast.success(translate("rsvp-saved"));
      resetGuests();
    } catch (error) {
      toast.error(translate("rsvp-error"));
      console.log("Error writing RSVP:", error);
    } finally {
      setIsLoadingRSVP(false);
      setLookupFirstName("");
      setLookupLastName("");
      setLookupEmail("");
    }
  };

  return { isLoadingRSVP, handleRSVPSubmit };
};
