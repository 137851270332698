import { useRef, useState } from "react";
import Slider from "react-slick";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import recommendImage from "../assets/images/Recommendations/Recommendations.webp";
import recommendMapImage from "../assets/images/Recommendations/RecommendationsMap.webp";
import Button from "../components/Button";
import Image from "../components/Image";
import PageContainer from "../components/PageContainer";
import InfoCard from "../components/Recommendations/InfoCard";
import {
  RECOMMENDATION_CATEGORIES,
  RECOMMENDATIONS,
  RECOMMENDATIONS_MAP,
  RECOMMENDATIONS_TITLES,
} from "../data/recommendations";
import { useLanguageContext } from "../context/language-context";

/**
 * React Slick - https://react-slick.neostack.com/
 * React Tabs - https://reactcommunity.org/react-tabs/
 *
 * TODO: Work on side list for carousel
 */
const Recommendations = () => {
  const { translate } = useLanguageContext();
  const [tabIndex, setTabIndex] = useState<RECOMMENDATION_CATEGORIES>(
    RECOMMENDATION_CATEGORIES.Restaurants,
  );
  const sliderRef = useRef<Slider | null>(null);

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: false,
  };

  const handleTabSelect = (index: number) => {
    const keys = Object.keys(RECOMMENDATION_CATEGORIES);
    setTabIndex(RECOMMENDATION_CATEGORIES[keys[index]]);
  };

  const handleButtonPress = (index) => {
    if (sliderRef.current) {
      console.log(index);
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <PageContainer page="things-to-do">
      <div className="mb-4 flex flex-col space-y-8">
        <div className="flex flex-col items-center bg-primary px-4 py-8 text-center text-white">
          <h1 className="text-3xl xl:text-4xl">{translate("recommendations")}</h1>
          <p className="max-w-5xl">
            {translate("recommendations-text")}
          </p>
        </div>
        <div className="flex flex-col">
          <div className="pb-2 pt-8 text-center">
            <h1 className="text-3xl xl:text-4xl">{translate("recommendations-map")}</h1>
          </div>
          <div className="flex flex-col justify-evenly xl:h-[32rem] xl:flex-row">
            <div className="">
              <Image
                src={recommendMapImage}
                alt="Google Map image with markers showing points of interest"
                classes="xl:h-[32rem] w-full"
              />
            </div>
            <div className="flex flex-col items-center justify-center bg-tan px-4 py-8 text-center xl:w-1/4">
              <p className="mb-4">
                {translate("recommendations-map-desc")}
                <br/>
                <br/>
                {translate("recommendations-map-desc-two")}
              </p>
              <a
                href={RECOMMENDATIONS_MAP}
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold text-secondary hover:text-tertiary"
              >
                {translate("recommendations-open-map")}
              </a>
              <p className="mt-4">
                {translate("recommendations-map-list-desc")}
              </p>
            </div>
            <div>
              <Image
                src={recommendImage}
                alt="Cat and Beto holding hands in front of iron door"
                classes="xl:h-[32rem] w-full"
              />
            </div>
          </div>
        </div>
        {/* <div className="mx-auto flex w-full flex-col space-y-4 text-white xl:w-2/3 xl:space-y-0">
          <div className="flex-row bg-secondary px-6 py-8 xl:px-12">
            <h3 className="mx-auto mb-8 px-4 font-assistant">
              There is truly incredible food all over the city and in every
              neighborhood! Wander and see what you can find! or use the map
              linked above. A few favorites below:
            </h3>
            <Tabs onSelect={(index) => handleTabSelect(index)}>
              <TabList>
                {Object.entries(RECOMMENDATIONS_TITLES).map(([key, value]) => {
                  return <Tab key={key}>{value}</Tab>;
                })}
              </TabList>
              <div className="flex flex-col lg:flex-row">
                <div className="my-4 flex flex-col lg:w-1/4">
                  {RECOMMENDATIONS[tabIndex].map((item, index) => (
                    <Button
                      key={item.name}
                      classes="p-2 my-1"
                      onClick={() => handleButtonPress(index)}
                    >
                      {item.name}
                    </Button>
                  ))}
                </div>
                <div className="rounded-lg bg-accent-primary p-8 lg:m-4 lg:w-3/4">
                  {Object.keys(RECOMMENDATIONS).map((key, index) => {
                    return (
                      <TabPanel key={index}>
                        <Slider
                          key={`main-slider-${index}`}
                          ref={(slider) => (sliderRef.current = slider)}
                          {...slickSettings}
                        >
                          {RECOMMENDATIONS[key].map((item) => (
                            <InfoCard key={item.name} data={item} />
                          ))}
                        </Slider>
                      </TabPanel>
                    );
                  })}
                </div>
              </div>
            </Tabs>
          </div>
        </div> */}
      </div>
    </PageContainer>
  );
};

export default Recommendations;
