import { IconContext } from "react-icons";
import { SiUber } from "react-icons/si";
import { useLanguageContext } from "../../context/language-context";

const TransportationInfo = () => {
  const { translate } = useLanguageContext();
  return (
    <div className="flex flex-col bg-accent-primary px-8 py-8 xl:h-80 xl:flex-row xl:py-4 xl:pl-4 xl:pr-12">
      <IconContext.Provider value={{ size: "8rem", color: "white" }}>
        <div className="mb-6 flex items-center justify-center bg-[#000000] px-8 xl:mb-0">
          <SiUber />
        </div>
      </IconContext.Provider>
      <div className="mb-4 ml-4">
        <h1 className="py-2">{translate("travel-tourist-title")}</h1>
        <div className="px-8 xl:px-16">
          <ul className="list-disc space-y-2">
            <li
              dangerouslySetInnerHTML={{
                __html: translate("travel-tourist-pointOne"),
              }}
            />
            <li>{translate("travel-tourist-pointTwo")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TransportationInfo;
