const ScrollToButton = ({ onClick, children }) => {
  return (
    <button
      className="m-auto h-24 w-24 rounded-full border-8 border-pure-white bg-pure-black text-white transition-colors duration-300 ease-in-out hover:cursor-pointer hover:border-b-tertiary hover:bg-black xl:h-32 xl:w-32"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ScrollToButton;
