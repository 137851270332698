import { createContext, useContext, useState } from "react";

interface Props {
  lookupFirstName: string;
  lookupLastName: string;
  lookupEmail: string;
  rsvpEmail: string;
  editRSVP: boolean;
  setLookupFirstName: (lookupFirstName: string) => void;
  setLookupLastName: (lookupLastName: string) => void;
  setLookupEmail: (lookupEmail: string) => void;
  setRSVPEmail: (rsvpEmail: string) => void;
  setEditRSVP: (editRSVP: boolean) => void;
}

const RSVPContext = createContext<Props | undefined>(undefined);

export const RSVPProvider = ({ children }) => {
  const [lookupFirstName, setLookupFirstName] = useState("");
  const [lookupLastName, setLookupLastName] = useState("");
  const [lookupEmail, setLookupEmail] = useState("");
  const [rsvpEmail, setRSVPEmail] = useState("");
  const [editRSVP, setEditRSVP] = useState(false);

  const value = {
    lookupFirstName,
    lookupLastName,
    lookupEmail,
    rsvpEmail,
    editRSVP,
    setLookupFirstName,
    setLookupLastName,
    setLookupEmail,
    setRSVPEmail,
    setEditRSVP,
  };

  return <RSVPContext.Provider value={value}>{children}</RSVPContext.Provider>;
};

export const useRSVPContext = () => {
  const context = useContext(RSVPContext);

  if (!context) {
    throw new Error("useRSVPContext must be used within a RSVPProvider");
  }

  return context;
};
