import Countdown from "../components/Home/Countdown";
import EventPoints from "../components/Home/EventPoints";
import Hero from "../components/Home/Hero";
import PageContainer from "../components/PageContainer";

export default function Home() {
  return (
    <PageContainer page="home">
      <Hero />
      <Countdown />
      <EventPoints />
    </PageContainer>
  );
}
