import Body from "./components/Body";
import FloatingButton from "./components/FloatingButton";
import { LanguageProvider } from "./context/language-context";
import "./firebase.js";
import "./index.css";

function App() {
  return (
    <LanguageProvider>
      <Body />
      <FloatingButton />
    </LanguageProvider>
  );
}

export default App;
