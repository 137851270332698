import flairImage from "../assets/images/Registry/RegistryFlair.webp";
import joiImage from "../assets/images/Registry/RegistryJoi.webp";
import mikaImage from "../assets/images/Registry/RegistryMika.webp";
import Button from "../components/Button";
import Image from "../components/Image";
import PageContainer from "../components/PageContainer";
import { useLanguageContext } from "../context/language-context";
import { REGISTRY_LINK } from "../data/registry";

const Registry = () => {
  const { translate } = useLanguageContext();
  const handleClick = () => {
    window.open(REGISTRY_LINK, "_blank", "noreferrer");
  };

  return (
    <PageContainer page="registry">
      <div className="mx-auto mb-12 flex w-full max-w-6xl flex-col justify-between bg-primary xl:h-[40rem] xl:flex-row">
        <div className="flex justify-evenly xl:hidden">
          <Image
            src={flairImage}
            alt="Picture of 3 white starbusts"
            classes="mx-auto w-1/4 xl:hidden"
          />
          <Image
            src={flairImage}
            alt="Picture of 3 white starbusts"
            classes="mx-auto w-1/4 scale-x-[-1] xl:hidden"
          />
        </div>
        <div className="hidden w-1/5 flex-col justify-between pt-4 xl:flex">
          <Image
            src={flairImage}
            alt="Picture of 3 white starbusts"
            classes="xl:h-48"
          />
          <Image src={joiImage} alt="Joi profile pic" />
        </div>
        <div className="flex flex-col items-center px-6 text-center text-white xl:basis-3/5">
          <h2 className="mb-4 mt-4 xl:mt-8 xl:text-6xl">
            {translate("registry-title")}
          </h2>
          <div className="mb-8 max-w-lg space-y-4">
            <p>{translate("registry-pointOne")}</p>
            <p>{translate("registry-pointTwo")}</p>
            <p>{translate("registry-pointThree")}</p>
            <p>{translate("registry-pointFour")}</p>
            <p>{translate("registry-pointFive")}</p>
          </div>
          <Button classes="bg-secondary" onClick={handleClick}>
            <h2>{translate("registry")}</h2>
          </Button>
        </div>
        <div className="hidden w-1/5 flex-col justify-between pt-4 xl:flex">
          <Image
            src={flairImage}
            alt="Picture of 3 white starbusts"
            classes="scale-x-[-1] xl:h-48"
          />
          <div className="flex w-3/4 self-end">
            <Image src={mikaImage} alt="Mika profile pic" />
          </div>
        </div>
        <div className="mt-8 flex justify-between">
          <Image
            src={joiImage}
            alt="Joi profile pic"
            classes="-left-4 w-1/2 xl:hidden"
          />
          <Image
            src={mikaImage}
            alt="Mika profile pic"
            classes="w-1/2 xl:hidden"
          />
        </div>
      </div>
    </PageContainer>
  );
};

export default Registry;
